export default function Arrow() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52.837 37.856">
      <g id="Group_161" data-name="Group 161" transform="translate(0 1.061)">
        <path
          id="Path_79"
          data-name="Path 79"
          d="M266.624-4013.8l17.867,17.867-17.867,17.867"
          transform="translate(-233.775 4013.796)"
          fill="none"
          stroke="#f8389a"
          strokeMiterlimit="10"
          strokeWidth="3"
        />
        <line
          id="Line_229"
          data-name="Line 229"
          x2="50.716"
          transform="translate(0 17.867)"
          fill="none"
          stroke="#f8389a"
          strokeMiterlimit="10"
          strokeWidth="3"
        />
      </g>
    </svg>
  );
}
