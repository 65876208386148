import Countdown from "react-countdown";
import Styled from "styled-components";

const StyledCountdown = Styled.div`
  display: ${props => (props.isHidden ? "none" : "block")};
`;

export default function HiddenCountdown({
  date,
  onComplete,
  renderer,
  children,
  isHidden
}) {
  return (
    <StyledCountdown isHidden={isHidden}>
      <Countdown
        date={date}
        onComplete={onComplete}
        key={date}
        renderer={renderer}
      >
        {children}
      </Countdown>
    </StyledCountdown>
  );
}
