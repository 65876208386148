import { Route, Switch } from "react-router-dom";
import Styled from "styled-components";

import { routes } from "./constants";
import "./App.css";

import Loading from "./components/loading";
import Home from "./screens/home";
import Login, { LoginComponent } from "./screens/login";
import { OnboardingComponent } from "./screens/onboarding";
import Agenda from "./screens/agenda";
import Community from "./screens/community";
import MainStage from "./screens/main-stage";
import OnDemand from "./screens/on-demand";
import Faqs from "./screens/faqs";

//import Globe from "./components/globe";
import ProtectedRoute from "./auth/protected-route";

const Wrapper = Styled.div`
  height: 100%;
  overflow: hidden;
`;

function App() {
  return (
    <Wrapper>
      <Switch>
        <ProtectedRoute path={routes.HOME} exact component={Home} />
        <Route path={routes.LOGIN} exact component={Login} />
        <Route path={`${routes.LOGIN}-test`} exact component={LoginComponent} />
        <Route path={`${routes.LOADING}-test`} component={Loading} />
        <ProtectedRoute path={routes.COMMUNITY} component={Community} />
        <ProtectedRoute path={routes.ON_DEMAND} component={OnDemand} />
      </Switch>
    </Wrapper>
  );
}

export default App;
