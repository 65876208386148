import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const custom_domain = process.env.REACT_APP_AUTH0_CUSTOM_DOMAIN;
const USERS_API = `https://${custom_domain}/api/v2/users/`;
const audience = `https://${domain}/api/v2/`;
const scope = "read:current_user";

export default function useUsersMetaData(key, initialValue) {
  const [userMetadata, setUserMetadata] = useState(null);
  const { user, getAccessTokenSilently, isAuthenticated } = useAuth0();

  useEffect(
    () => {
      if (isAuthenticated) {
        // gets user meta data
        const getUserMetadata = async () => {
          try {
            const accessToken = await getAccessTokenSilently({
              audience,
              scope
            });

            const userDetailsByIdUrl = `${USERS_API}${user.sub}`;
            const metadataResponse = await fetch(userDetailsByIdUrl, {
              headers: {
                Authorization: `Bearer ${accessToken}`
              }
            });

            const { user_metadata } = await metadataResponse.json();
            setUserMetadata(user_metadata);
          } catch (e) {
            console.log(e.message);
          }
        };

        getUserMetadata();
      }
    },
    [isAuthenticated]
  );

  return [{ ...user, ...userMetadata }];
}
