import React, { createContext, useState, useEffect } from "react";
import moment from "moment";

import { event_types } from "../constants";
import europe from "../data/europe";
import asia from "../data/asia";
import northamerica from "../data/north-america";
import useUsersMetaData from "./use-meta-data";
const UserMetaContext = createContext(null);
export { UserMetaContext };

const regions = {
  APAC: asia,
  EUROPE: europe,
  AMERICAS: northamerica
};

const sortByStartTime = (a, b) => {
  if (a.duration.starttime < b.duration.starttime) {
    return -1;
  }
  if (a.duration.starttime > b.duration.starttime) {
    return 1;
  }
  // a must be equal to b
  return 0;
};

const sortByDayAndTime = data => {
  const hashmap = data.reduce((accumulator, currentValue) => {
    return {
      ...accumulator,
      [currentValue.date]: accumulator[currentValue.date]
        ? [...accumulator[currentValue.date], currentValue]
        : [currentValue]
    };
  }, {});

  const arrays = [];
  Object.keys(hashmap).map((key, index) => {
    arrays[index] = hashmap[key].sort(sortByStartTime);
  });

  return { arrays, hashmap };
};

const getEventsForToday = (arrays, hashmap) => {
  // get todays date using the timezone of the EVENT
  // this way, incase the local time has changed date we still use the date of the event
  const timezoneIndex = arrays[0][0].duration.starttime.indexOf("+");
  const timezone = arrays[0][0].duration.starttime.substring(timezoneIndex);
  const today = moment()
    .utcOffset(timezone)
    .format("YYYY-MM-DD");
  return hashmap[today] || [];
};

const getHasEventStarted = arrays => {
  return moment().isAfter(arrays[0][0].duration.starttime);
};

const getHasEventEnded = arrays => {
  const lastDay = arrays[arrays.length - 1];
  const lastEvent = lastDay[lastDay.length - 1];
  return moment().isAfter(lastEvent?.duration?.endtime);
};

const getCurrentEvent = events => {
  return events.find(event => {
    return moment().isBetween(event.duration.starttime, event.duration.endtime);
  });
};

const getLiveEvent = events => {
  return events.filter(event => event.type === event_types.LIVE).find(event => {
    // get the first live event that hasn't finished yet
    return moment().isBefore(event.duration.endtime);
  });
};

const getSecondsFromStart = day1 => {
  const now = moment();
  const seconds = moment
    .duration(now.diff(moment(day1[0].duration.starttime)))
    .asSeconds();

  if (seconds < 0) {
    return 0;
  }
  return seconds > 20000 ? 20000 : seconds;
};

export default function UserMetaProvider({ children }) {
  const [user] = useUsersMetaData();
  const [live, setLive] = useState(undefined);
  const [currentEvent, setCurrentEvent] = useState(undefined);
  const [days, setDays] = useState([]);
  //const [forwardFund, setForwardFund] = useState(0);
  const [hasEventStarted, setHasEventStarted] = useState(false);
  const [hasEventEnded, setHasEventEnded] = useState(false);

  const getEvents = () => {
    if (!user.region) return false;
    //console.log("user.region: ", user.region);
    const dataForUser = regions[user.region] || [];
    // filter out breakouts that user is not assigned to
    const eventsForUser = dataForUser.filter(event => {
      if (event.type === event_types.BREAKOUT) {
        return user[event.breakoutid] === event.groupid;
      } else {
        return true;
      }
    });

    // get events as hashmap AND as map-able arrays
    const { arrays, hashmap } = sortByDayAndTime(eventsForUser);
    setDays(arrays);

    const eventsToday = getEventsForToday(arrays, hashmap);
    setHasEventStarted(getHasEventStarted(arrays));
    setHasEventEnded(getHasEventEnded(arrays));

    setLive(getLiveEvent(eventsToday));
    setCurrentEvent(getCurrentEvent(eventsToday));
    // get time from NOW - FIRST EVENT in SECONDS
    //setForwardFund(getSecondsFromStart(arrays[0]));
  };

  useEffect(
    () => {
      getEvents();
    },
    [user?.region]
  );

  // const getForwardFund = () => {
  //   // get time from NOW - FIRST EVENT in SECONDS
  //   setForwardFund(getSecondsFromStart(days[0]));
  // };

  const onLiveComplete = () => {
    setTimeout(() => {
      getEvents();
    }, 1000);
    // get next live
  };

  return (
    <UserMetaContext.Provider
      value={{
        user,
        days,
        currentEvent,
        live,
        onLiveComplete,
        hasEventStarted,
        hasEventEnded
      }}
    >
      {children}
    </UserMetaContext.Provider>
  );
}
