import Styled from "styled-components";
//import { Link } from "react-router-dom";

import { theme, device } from "../../constants";
import pxToRem from "../../utils/pxToRem";

const StyledFooter = Styled.footer`
  position: absolute;
  bottom: 24px;
  color: ${props => (props.mode === "dark" ? theme.secondary : theme.white)};
  padding: 0 25px;
  display: flex;
  flex-direction: column;

  padding: 0 15px;

  font-size: ${pxToRem(12)};
  line-height: ${pxToRem(18)};

  @media ${device.desktop} {
    padding: 0 25px;
  }
`;

const Item = Styled.span`
  @media ${device.desktop} {
    br {
      display: none;
    }
  }
`;

// const Links = Styled.div`
//   margin-bottom: ${pxToRem(16)};
//   display: flex;
//   flex-direction: column;
//
//   & ${Item} {
//     margin-top: ${pxToRem(4)};
//     display: block;
//   }
//
//   @media ${device.desktop} {
//     flex-direction: row;
//       margin-bottom: ${pxToRem(0)};
//
//       & ${Item} {
//         margin-top: ${pxToRem(0)};
//       }
//   }
// `;
//
// const ALink = Styled.a`
//   color: ${props => (props.mode === "dark" ? theme.secondary : theme.white)};
//   text-decoration: none;
//
//   &:visited {
//     color: ${props => (props.mode === "dark" ? theme.secondary : theme.white)};
//   }
// `;
//
// const Divider = Styled.div`
//   width: 1px;
//   background: ${props =>
//     props.mode === "dark" ? theme.secondary : theme.white};
//   margin: 0 30px;
//     height: 23px;
//
//   display: none;
//
//   @media ${device.desktop} {
//     display: unset;
//   }
// `;

const Inner = Styled.div`
  display: flex;
  flex-direction: column-reverse;
  height: 100%;

  @media ${device.desktop} {
    align-items: center;
    flex-direction: row;
  }
`;

const Copy = Styled.p`
  max-width: ${pxToRem(383)};
  font-size: ${pxToRem(10)};
  line-height: ${pxToRem(13)};
  margin-top: 1rem;
`;

export default function Footer({ mode }) {
  return (
    <StyledFooter mode={mode}>
      <Inner>
        <Item>
          &copy; Copyright Reckitt Benckiser Group plc. <br />All rights
          reserved
        </Item>
      </Inner>
      <Copy>
        Your personal data will be processed to run the conference in accordance
        with the Employee Privacy Statement, which is available on your myRB
        dashboard.
      </Copy>
    </StyledFooter>
  );
}
