import Styled from "styled-components";

import pxToRem from "../../utils/pxToRem";
import { theme, device } from "../../constants";

import images from "./images";

export const IMAGE_HEIGHT = {
  MD: 331
};

const Wrapper = Styled.div`
  height: ${pxToRem(234)};
  width: 100%;
  overflow: hidden;
  background: ${theme.black};
  flex: 0 0 auto;
  background: url(${props => props.sm}) no-repeat center center / cover;

  @media ${device.tablet} {
    background: url(${props => props.md}) no-repeat center center / cover;
  }

  @media ${device.desktop} {
    background: url(${props => props.lg}) no-repeat top left / cover;
  }

  ${props =>
    props.isLastSlide &&
    `@media ${device.mobile} {
    display: none;
  }`}

  @media ${device.tablet} {
    height: ${pxToRem(IMAGE_HEIGHT.MD)};
    width: 100vw;
  }

  @media ${device.desktop} {
    height: 100%;
    width: 33%;
    min-width: 33%;
  }
`;

export default function Image({ slide }) {
  return (
    <Wrapper
      sm={images[slide]?.sm || images[0].sm}
      md={images[slide]?.md || images[0].md}
      lg={images[slide]?.lg || images[0].lg}
    />
  );
}
