import Styled from "styled-components";
import moment from "moment";

import { theme, device } from "../../../constants";
import pxToRem from "../../../utils/pxToRem";
import Section from "./Section";
import CalendarItem from "../calendar";

const DayWrapper = Styled.div`
  padding: ${pxToRem(32)} 0;
  position: relative;
  width: 100%;

  @media ${device.desktop} {
    margin-top: -${pxToRem(32)};
  }
`;

const Title = Styled.h1`
  color: ${theme.primary};
  font-size: ${pxToRem(50)};
  line-height: ${pxToRem(40)};
  text-transform: uppercase;
  ${theme.font_display};
  margin-bottom: 5px;

  @media ${device.tablet} {
    font-size: ${pxToRem(80)};
    line-height: ${pxToRem(40)};
    margin-bottom: 35px;
    flex: 0 0 auto;
    margin-right: 100px;
  }

  @media ${device.desktop} {
    width: ${pxToRem(125)};
  }
`;

const TimeWrapper = Styled.div`
  width: 100%;
`;
const CalendarWrapper = Styled.div`
  position: absolute;
  top: 2rem;
  width: 100%;
`;

const Outer = Styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.desktop} {
    flex-direction: row;
  }

  & + & {
    margin-top: 100px;
  }
`;

const renderCalendarTimeRows = (startTime, endTime, data) => {
  let time = moment(startTime).toDate();
  const end = moment(endTime);
  //let i = 0;
  let times = [time];
  do {
    //i = i + 1;
    const currentTime = moment(time).add(30, "m");
    time = currentTime.toDate();
    times.push(currentTime);
  } while (moment(time).isBefore(end));
  return times;
};

export default function Day({ day, data, onItemClick, user }) {
  const startTime = data[0].duration.starttime;
  const finishTime = data[data.length - 1].duration.endtime;

  const timesToRender = renderCalendarTimeRows(startTime, finishTime, data);
  return (
    <Outer>
      <Title>Day {day}</Title>
      <DayWrapper>
        <TimeWrapper>
          {timesToRender.map((item, index) => (
            <Section
              key={item}
              time={moment(item).format("HH:mm")}
              isLast={index === timesToRender.length - 1}
            />
          ))}
        </TimeWrapper>
        <CalendarWrapper>
          {data.map(item => (
            <CalendarItem
              dayStartTime={moment(startTime)}
              duration={item.duration.duration}
              key={item.duration.starttime}
              data={item}
              onClick={onItemClick}
            />
          ))}
        </CalendarWrapper>
      </DayWrapper>
    </Outer>
  );
}
