import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import smoothscroll from "smoothscroll-polyfill";
import ReactGA from "react-ga";

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";

import Auth0ProviderWithHistory from "./auth/auth0-provider-with-history";
//import FirebaseProvider from "./utils/firebase";
import UserMetaProvider from "./utils/metadata-context";

// kick off the polyfill!
smoothscroll.polyfill();

const TRACKING_ID = "UA-192210743-1"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ScrollToTop />
      <Auth0ProviderWithHistory>
        <UserMetaProvider>
          <App />
        </UserMetaProvider>
      </Auth0ProviderWithHistory>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
