import Styled from "styled-components";
import Carousel from "./Carousel";
import Intro from "../Intro";
import Slide from "./Slide";
import Overlay from "../Overlay";

import data from "../data";
//import background from "./background.png";

const Wrapper = Styled.div`
  height: 100vh;
`;

/* .slick-track {
  background: url(${background});
  background-size: cover;
} */

export default function HomeCarousel({ onClick }) {
  return (
    <Wrapper>
      <Carousel>
        <Slide index={0}>
          <Intro />
        </Slide>
        {data.map((item, index) => (
          <Slide key={index} index={index + 1}>
            <Overlay {...item} onClick={() => onClick(index)} />
          </Slide>
        ))}
      </Carousel>
    </Wrapper>
  );
}
