import Styled from "styled-components";

import { theme, device } from "../../../constants";
import pxToRem from "../../../utils/pxToRem";

import {
  TIME_WIDTH,
  TIME_WIDTH_TABLET,
  TIME_HEIGHT,
  TIME_HEIGHT_TABLET
} from "../constants";

const Wrapper = Styled.div`
  position: relative;
`;

const Time = Styled.div`
  position: absolute;
  display: block;
  width: 100%;

  span {
    border-radius: 5px;
    color: ${theme.white};
    background: ${theme.copy_dark};
    width: ${pxToRem(TIME_WIDTH)};
    height: ${pxToRem(22)};
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${pxToRem(14)};

    @media ${device.tablet} {
      width: ${pxToRem(TIME_WIDTH_TABLET)};
      height: ${pxToRem(25)};
    }
  }

  &:before {
    content: ' ';
    display: block;
    width: 100%;
    height: 2px;
    background: ${theme.copy_dark};
    top: 0;
    position: absolute;
  }
`;

// 10 mins marker
const Split = Styled.div`
  border-top: 2px solid #CACFD8;
  height: ${pxToRem(TIME_HEIGHT)};

  @media ${device.tablet} {
    height: ${pxToRem(TIME_HEIGHT_TABLET)};
  }
`;

export default function Section({ time, isLast }) {
  return (
    <Wrapper>
      <Time>
        <span>{time}</span>
      </Time>
      {!isLast && (
        <>
          <Split />
          <Split />
          <Split />
        </>
      )}
    </Wrapper>
  );
}
