import Styled from "styled-components";
import { theme, device, deviceMax } from "../../constants";

import Arrow from "./Arrow";

const ArrowWrapper = Styled.div`
  height: 100vh;
  position: absolute;
  top: 0;
`;

const ArrowWrapperPrev = Styled(ArrowWrapper)`
  left: 35px;

  @media ${device.desktop} {
    left: 25px;
  }
`;

const ArrowWrapperNext = Styled(ArrowWrapper)`
  right: 35px;

  @media ${device.desktop} {
    right: 25px;
  }
`;

const Button = Styled.button`
  position: absolute;
  top: 50%;
  display: block;
  width: 65px;
  height: 65px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: ${theme.white};
  border-radius: 50%;


    box-shadow: 0px 0px 15px #00000065;

  svg {
    width: 50%;
  }

  &[disabled] {
    opacity: 0;
    cursor: auto;
  }

  display: none;

  @media ${device.tablet} {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const Next = Styled(Button)`
  right: 32px;

  ${({ isGrey }) => {
    return (
      isGrey?.length > 0 &&
      isGrey.map(bp => {
        if (bp === "md") {
          return `@media ${device.tablet} and ${deviceMax.tablet} {
            background: ${theme.grey}
          }`;
        }

        if (bp === "lg") {
          return `@media ${device.tablet} {
            background: ${theme.grey}
          }`;
        }
      })
    );
  }}
`;

const Prev = Styled(Button)`
  left: 32px;
  z-index: 1;
  transform: translate(0, -50%) rotate(180deg);
  ${({ isGrey }) => {
    return (
      isGrey?.length > 0 &&
      isGrey.map(bp => {
        if (bp === "md") {
          return `@media ${device.tablet} and ${deviceMax.tablet} {
            background: ${theme.grey}
          }`;
        }

        if (bp === "lg") {
          return `@media ${device.tablet} {
            background: ${theme.grey}
          }`;
        }
      })
    );
  }}
`;

// ${props =>
//   !props.isWhite &&
//   `@media ${device.tablet} {
//   background: ${theme.grey}
// }`}

function NextArrow({ onClick, currentSlide, slideCount, isGrey }) {
  const isDisabled = currentSlide === slideCount - 1;
  return (
    <Next
      className="rb-arrow rb-arrow-next"
      aria-label="Go to next slide"
      onClick={onClick}
      disabled={isDisabled}
      isGrey={isGrey}
    >
      <Arrow />
    </Next>
  );
}

function PrevArrow({ onClick, currentSlide, isGrey }) {
  const isDisabled = currentSlide === 0;
  return (
    <Prev
      className="rb-arrow rb-arrow-prev"
      aria-label="Go to prev slide"
      onClick={onClick}
      disabled={isDisabled}
      isGrey={isGrey}
    >
      <Arrow />
    </Prev>
  );
}

function NextArrowWrapper({ onClick, currentSlide, isGrey, slideCount }) {
  return (
    <ArrowWrapperNext className="rb-arrow-wrapper">
      <NextArrow
        onClick={onClick}
        isGrey={isGrey}
        currentSlide={currentSlide}
        slideCount={slideCount}
      />
    </ArrowWrapperNext>
  );
}

function PrevArrowWrapper({ onClick, currentSlide, isGrey }) {
  return (
    <ArrowWrapperPrev className="rb-arrow-wrapper">
      <PrevArrow
        onClick={onClick}
        isGrey={isGrey}
        currentSlide={currentSlide}
      />
    </ArrowWrapperPrev>
  );
}

export { NextArrow, PrevArrow, PrevArrowWrapper, NextArrowWrapper };
