import UK from "./images/Hub_UK.png";
import US from "./images/Hub_US.png";
import India from "./images/Hub_India.png";
import China from "./images/Hub_China.png";
import Mexico from "./images/Hub_Mexico.png";
const data = [
  {
    img: UK,
    video:
      "https://web.microsoftstream.com/embed/video/10a30866-fae9-4c43-b3c5-10bbe63cf097",
    copy: "Putting People and Consumers First: Going above and beyond in Hull",
    name: "UK",
    location: "UK"
  },
  {
    img: US,
    video:
      "https://web.microsoftstream.com/embed/video/cbd354d5-e043-49df-9f67-0289ba332418",
    copy: "Protect, Heal and Nurture in the US: Vitamin Angels and Enfamil",
    name: "US",
    location: "US"
  },
  {
    img: India,
    video:
      "https://web.microsoftstream.com/embed/video/4c24eae6-495b-4ad7-822b-d737e146f605",
    copy: "How Dettol (and TikTok) got the world to wash its hands",
    name: "India",
    location: "India"
  },
  {
    img: China,
    video:
      "https://web.microsoftstream.com/embed/video/62dd0654-b3ac-4d0c-8501-3e697bad7f18",
    copy: "Taicang Factory",
    name: "China",
    location: "China"
  },
  {
    img: Mexico,
    video:
      "https://web.microsoftstream.com/embed/video/b6b53700-0133-4b8f-9cfc-56f3e71c2edb",
    copy: "Promoting Sexual Wellbeing in Mexico",
    name: "Mexico",
    location: "Mexico"
  }
];

export default data;
