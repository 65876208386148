import Styled from "styled-components";

import pxToRem from "../../../utils/pxToRem";
import Intro from "../Intro";
import Overlay from "../Overlay";
import data from "../data";

const Wrapper = Styled.div`
  height: 100%;
  padding: ${pxToRem(145)} ${pxToRem(10)};
`;

const Items = Styled.div`
  margin-top: ${pxToRem(37)};
`;

const Item = Styled.div`
  margin-bottom: ${pxToRem(30)};
`;

export default function List({ onClick }) {
  return (
    <Wrapper>
      <Intro />

      <Items>
        {data.map((item, index) => (
          <Item key={index}>
            <Overlay {...item} onClick={() => onClick(index)} />
          </Item>
        ))}
      </Items>
    </Wrapper>
  );
}
