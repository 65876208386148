import Styled from "styled-components";
import { rgba } from "polished";
import ReactGA from "react-ga";
import { device } from "../../../constants";
import pxToRem from "../../../utils/pxToRem";
import { formatSpeakers } from "../../../utils/format-speakers";
import { durationInMinutes } from "../../../utils/duration-in-minutes";

import Modal from "../../../components/Modal";
import Clock from "../calendar/Clock";
import Speaker from "./Speaker";
import Button from "../calendar/Button";

import { Image, ImgWrapper } from "./Image";
import { Meta, Location } from "./Meta";

const Title = Styled.h2`
  font-size: ${pxToRem(18)};
  line-height: ${pxToRem(22)};

  @media ${device.tablet} {
    font-size: ${pxToRem(19)};
    line-height: ${pxToRem(23)};
  }

  @media ${device.desktop} {
    font-size: ${pxToRem(25)};
    line-height: ${pxToRem(32)};
  }
`;

const HeaderCopy = Styled.div`
  display: flex;
  flex-direction: column;
`;

const Content = Styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;

  @media ${device.tablet} {
    flex-direction: row;
    align-items: stretch;
  }
`;

const CopyWrapper = Styled.div`
  border-top: 2px solid ${rgba("#707070", 0.1)};
  border-bottom: 2px solid ${rgba("#707070", 0.1)};
  padding: 12px 0;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Description = Styled.p`
  font-size: ${pxToRem(16)};
  line-height: ${pxToRem(20)};
  color: #1F3048;
`;
const Featuring = Styled.h2`
  font-size: ${pxToRem(16)};
  line-height: ${pxToRem(24)};
  margin-bottom: 12px;
`;
const SpeakersWrapper = Styled.div`
  padding: 0 0 11px;
`;
const Speakers = Styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px 2.75%;
`;

const ButtonWrapper = Styled.div`
  display: inline-flex;
  flex-direction: column;
  height: ${props => (props.withoutHeight ? "auto" : pxToRem(34))};

  a {
    margin-left: 0;
  }
`;

export default function AgendaModal({ isOpen, onClose, data }) {
  const {
    title,
    desc,
    location,
    image,
    link,
    type,
    duration,
    backuplink1,
    backuplink2
  } = data;
  const backupLinks = [backuplink1, backuplink2].filter(item => !!item);

  const speakers = formatSpeakers(data);
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header={
        <HeaderCopy>
          <Title>{title}</Title>
        </HeaderCopy>
      }
    >
      <>
        <Content>
          <ImgWrapper>
            <Image src={`events/${image}`} alt="" />
          </ImgWrapper>
          <CopyWrapper>
            <Description>{desc}</Description>

            {link && (
              <ButtonWrapper withoutHeight={backupLinks.length > 0}>
                <Button
                  href={link}
                  type={type}
                  starttime={duration?.starttime}
                  endtime={duration?.endtime}
                  backupLinks={backupLinks}
                  id={title}
                  onClick={() => {
                    ReactGA.event({
                      category: "Agenda",
                      action: `${type} clicked`
                    });
                  }}
                  onBackuplinkClick={() => {
                    ReactGA.event({
                      category: "Agenda",
                      action: "Backup link clicked"
                    });
                  }}
                />
              </ButtonWrapper>
            )}
          </CopyWrapper>
        </Content>
        {speakers.length > 0 && (
          <SpeakersWrapper>
            <Featuring>Featuring</Featuring>
            <Speakers>
              {speakers.map(item => <Speaker key={item.name} {...item} />)}
            </Speakers>
          </SpeakersWrapper>
        )}

        <Meta>
          <Clock>{durationInMinutes(data)} minutes.</Clock>
          <Location>{location}</Location>
        </Meta>
      </>
    </Modal>
  );
}
