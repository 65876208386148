import lg1 from "./lg/1.jpg";
import lg2 from "./lg/2.jpg";
import lg3 from "./lg/3.jpg";
import lg4 from "./lg/4.jpg";
import lg5 from "./lg/5.jpg";
import lg6 from "./lg/6.jpg";

export const images = {
  lg: [lg1, lg2, lg3, lg4, lg5, lg6]
};
