import Styled from "styled-components";
import { rgba } from "polished";

import pxToRem from "../../../utils/pxToRem";
import { device } from "../../../constants";

const Meta = Styled.div`
  font-size: ${pxToRem(10)};
  display: flex;
  align-items: center;
  border-top: 2px solid ${rgba("#707070", 0.1)};

  @media ${device.tablet} {
    font-size: ${pxToRem(12)};
    line-height: ${pxToRem(18)};
  }
`;

const Location = Styled.div`
  padding: 10px 0 10px 10px;
  border-left: 1px solid ${rgba("#575757", 0.1)};
`;

export { Meta, Location };
