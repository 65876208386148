import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Styled from "styled-components";
import Div100vh from "react-div-100vh";
import { useAuth0 } from "@auth0/auth0-react";

import pxToRem from "../../utils/pxToRem";
import { theme, routes, device } from "../../constants";
import Loading from "../../components/loading";

import Content from "./Content";
import Logo from "./Logo";

import backgroundLG from "./login_2048.jpg";
import backgroundSM from "./login_767.jpg";
import backgroundMD from "./login_1023.jpg";

const Wrapper = Styled.div`
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background: ${theme.black};

  background: url(${backgroundSM}) no-repeat center center / cover;

  @media ${device.tablet} {
    background: url(${backgroundMD}) no-repeat center center / cover;
  }

  @media ${device.desktop} {
    background: url(${backgroundLG}) no-repeat center center / cover;
  }
`;

const Filler = Styled.div`
  flex: 1;
`;

const Inner = Styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1;
  width: 100%;
  margin: auto;

  @media ${device.tablet} {
    max-width: ${pxToRem(320)};
  }
`;

export default function Login({ location, history, shouldRedirect }) {
  const [authIsLoading, setAuthIsLoading] = useState(true);
  const fromUrl = location?.state?.from?.pathname;
  const { isAuthenticated, isLoading } = useAuth0();
  console.log("login page auth isLoading: ", isLoading);

  useEffect(
    () => {
      console.log("login page  isAuthenticated changed: ", isAuthenticated);
      if (isAuthenticated && shouldRedirect) {
        history.push(fromUrl || routes.HOME);
      }
    },
    [isAuthenticated, fromUrl, history, shouldRedirect]
  );

  useEffect(
    () => {
      setAuthIsLoading(isLoading);
    },
    [isLoading]
  );

  return (
    <Div100vh>
      <Wrapper>
        {authIsLoading ? (
          <Loading />
        ) : (
          <Inner>
            <Logo />
            <Content />
            <Filler />
          </Inner>
        )}
      </Wrapper>
    </Div100vh>
  );
}

Login.propTypes = {
  shouldRedirect: PropTypes.bool
};

Login.defaultProps = {
  shouldRedirect: true
};
