export default () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.686 23.448">
      <g transform="translate(0 0.707)">
        <path
          fill="rgba(0,0,0,0)"
          stroke="#fff"
          strokeMiterlimit="10"
          strokeWidth="2px"
          d="M266.624-4013.8l11.017,11.017-11.017,11.017"
          transform="translate(-246.369 4013.796)"
        />
        <line
          fill="none"
          stroke="#fff"
          strokeMiterlimit="10"
          strokeWidth="2px"
          x2="31.272"
          transform="translate(0 11.017)"
        />
      </g>
    </svg>
  );
};
