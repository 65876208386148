import Styled from "styled-components";
import { device } from "../../constants";
import logo from "./logo.png";

const Wrapper = Styled.div`
  width: 90px;
  flex: 1;
  display: flex;
  align-items: center;

  @media ${device.tablet} {
    width: 228px;
  }
`;

const Img = Styled.img`
  max-width: 100%;
  transform: translateX(-20px);

  @media ${device.tablet} {
    transform: translateX(-25px);
  }
`;

export default function Logo() {
  return (
    <Wrapper>
      <Img src={logo} />
    </Wrapper>
  );
}
