import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PrevArrowWrapper, NextArrowWrapper } from "../../components/Arrows";

export default function Carousel({ children }) {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrowWrapper isGrey={["md"]} />,
    prevArrow: <PrevArrowWrapper isGrey={["md", "lg"]} />
  };
  return <Slider {...settings}>{children}</Slider>;
}
