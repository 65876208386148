import { useState, useEffect, useContext } from "react";
import Styled from "styled-components";

import Loading from "../../components/loading";
import { theme, device, event_types } from "../../constants";
import pxToRem from "../../utils/pxToRem";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

import { UserMetaContext } from "../../utils/metadata-context";

import Intro from "./Intro";
import Day from "./day";
import Modal from "./modal";

const Wrapper = Styled.div`
  background: ${theme.grey_background};
  min-height: 100vh;
  position: relative;
`;

const InnerWrapper = Styled.div`
  padding: ${pxToRem(87)} ${pxToRem(10)} ${pxToRem(160)};

  @media ${device.tablet} {
    padding: ${pxToRem(180)} ${pxToRem(10)};
  }

  @media ${device.desktop} {
    padding: ${pxToRem(150)} ${pxToRem(25)};
  }
`;

export default function Agenda() {
  const { user, days, currentEvent } = useContext(UserMetaContext);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selected, setSelected] = useState({});

  const onModalClose = () => {
    setSelected({});
    setModalIsOpen(false);
  };

  useEffect(
    () => {
      if (modalIsOpen) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "unset";
      }
    },
    [modalIsOpen]
  );

  useEffect(
    () => {
      const currentEl = document.getElementById(
        currentEvent?.duration?.starttime
      );
      if (currentEl) {
        currentEl.scrollIntoView();
      }
    },
    [currentEvent?.duration]
  );

  const onClick = selectedEvent => {
    if (selectedEvent.type !== event_types.BREAK) {
      setSelected(selectedEvent);
      setModalIsOpen(true);
    }
  };

  if (!user.region) {
    return <Loading />;
  }

  return (
    <Wrapper>
      <InnerWrapper>
        <Header mode="dark" />
        <Intro user={user} />
        {days.map((day, index) => (
          <Day
            key={index}
            user={user}
            day={index + 1}
            data={day}
            onItemClick={onClick}
          />
        ))}
      </InnerWrapper>
      <Footer mode="dark" />

      <Modal isOpen={modalIsOpen} onClose={onModalClose} data={selected} />
    </Wrapper>
  );
}
