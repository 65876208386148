export default function Video() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 51">
      <g transform="translate(-10 -145.872)">
        <path
          fill="#ff007f"
          d="M9.4,4.558a3,3,0,0,1,5.209,0l6.83,11.953A3,3,0,0,1,18.83,21H5.17a3,3,0,0,1-2.6-4.488Z"
          transform="translate(49 159.872) rotate(90)"
        />
        <g
          fill="none"
          stroke="#ff007f"
          strokeWidth="2px"
          transform="translate(10 145.872)"
        >
          <ellipse stroke="none" cx="26" cy="25.5" rx="26" ry="25.5" />
          <ellipse fill="none" cx="26" cy="25.5" rx="25" ry="24.5" />
        </g>
      </g>
    </svg>
  );
}
