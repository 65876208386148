import { useState, useEffect } from "react";
import Styled from "styled-components";
import Div100vh from "react-div-100vh";

import pxToRem from "../../utils/pxToRem";
import { theme, device, deviceMax } from "../../constants";

import data, { safari } from "./data";

import Intro from "./Intro";
import Title from "./Title";
//import Charity from "./Charity";
import Carousel from "./Carousel";
import Slide from "./Slide";
import Image, { IMAGE_HEIGHT } from "./Image";
import Logo from "./Logo";

import "./onboarding.scss";

const Wrapper = Styled.div`
  background: ${theme.white};
  height: 100%;

  .rb-arrow-wrapper {
    @media ${device.tablet} and ${deviceMax.tablet}{
      padding-top: ${pxToRem(IMAGE_HEIGHT.MD)};
      padding-bottom: 45px; // dots position + height
      display: flex;
      align-items: center;

      button {
        position: unset;
      }
    }

    @media ${device.desktop}{
      .rb-arrow-next {
        right: 0;
      }

      .rb-arrow-prev {
        left: 0;
      }
    }
  }
`;

const ContentOuter = Styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-bottom: 55px; // dots position + height
    overflow-y: hidden;

    @media ${device.tablet} {
      padding-right: 100px;
      padding-left: 100px;
      padding-bottom: 45px; // dots position + height
    }

    @media ${device.desktop} {
      flex: 1 1 auto;
      padding-left: 145px;
      align-items: flex-start;
    }
`;

const Content = Styled.div`
  max-width: ${pxToRem(260)};
  max-height: 100%;

  @media ${device.mobile} {
    padding: 20px 0;
    overflow-y: scroll;
  }

  @media ${device.tablet} {
    max-width: ${pxToRem(482)};
  }

  @media ${device.desktop} {
    max-width: 500px;
  }
`;

const Copy = Styled.p`
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(18)};
  margin-top: ${pxToRem(14)};

  ${theme.font_regular};
  color: ${theme.secondary};


  @media ${device.tablet} {
    font-size: ${pxToRem(18)};
    line-height: ${pxToRem(24)};
    margin-top: ${pxToRem(21)};
  }

  ${props =>
    props.hiddenSm &&
    `
    @media ${device.mobile} {
      display: none;
    }
    `}
`;

const Ol = Styled.ol`
  margin-top: ${pxToRem(14)};

  @media ${device.tablet} {
    margin-top: ${pxToRem(21)};
  }
`;

const Li = Styled.li`
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(18)};

  ${theme.font_regular};
  color: ${theme.secondary};

  @media ${device.tablet} {
    font-size: ${pxToRem(18)};
    line-height: ${pxToRem(24)};
  }
`;

const Cta = Styled.button`
  background: ${theme.primary};
  color: ${theme.white};
  border-radius: 5px;
  ${theme.font_bold};
  font-size: ${pxToRem(17)};
  height: ${pxToRem(43)};
  width: ${pxToRem(183)};
  margin-top: 35px;
  border: none;

  @media ${device.tablet} {
    height: ${pxToRem(53)};
    width: ${pxToRem(230)};
    font-size: ${pxToRem(21)};
    margin-top: 85px;
  }
`;

export default function Onboarding({ onComplete }) {
  const [isSafari, setIsSafari] = useState(false);
  const [screens, setScreens] = useState(data);
  useEffect(
    () => {
      let isSafariBrowser =
        navigator.vendor.match(/apple/i) &&
        !navigator.userAgent.match(/crios/i) &&
        !navigator.userAgent.match(/fxios/i);

      if (isSafariBrowser && !isSafari) {
        const withSafari = [...screens];
        setIsSafari(isSafariBrowser);
        withSafari.splice(1, 0, { ...safari });
        setScreens(withSafari);
      }
    },
    [isSafari, screens]
  );
  // const onCharitySelect = selected => {
  //   onComplete(selected);
  // };

  return (
    <Div100vh>
      <Wrapper>
        <Carousel>
          {screens.map((item, index) => {
            const copy = item?.copy?.split("\n");
            return (
              <Slide key={item.id}>
                <Image slide={item.id} />

                <ContentOuter>
                  {index === 0 && <Logo />}
                  <Content>
                    <Intro>{item.intro}</Intro>
                    <Title copy={item.title}>{item.title}</Title>

                    {item.copy &&
                      copy.map(text => (
                        <Copy hiddenSm={index === 0}>{text}</Copy>
                      ))}

                    {item.isSafari && (
                      <>
                        <Copy>
                          You'll need to adjust some browser settings to watch
                          Microsoft Stream content:
                        </Copy>

                        <Ol>
                          <Li>
                            1. Go to <strong>'Preference'</strong>
                          </Li>
                          <Li>
                            2. Select the <strong>'Privacy'</strong> tab
                          </Li>
                          <Li>
                            3. Deselect{" "}
                            <strong>'Prevent cross-site tracking'</strong> and{" "}
                            <strong>'Block all cookies'</strong>
                          </Li>
                          <Li>
                            4. Refresh your browser and the content will play
                          </Li>
                        </Ol>

                        <Copy>
                          You may wish to reenable these settings after using
                          the site.
                        </Copy>
                      </>
                    )}

                    {index === screens.length - 1 && (
                      <Cta onClick={() => onComplete()}>
                        Join the conference
                      </Cta>
                    )}
                  </Content>
                </ContentOuter>
              </Slide>
            );
          })}
        </Carousel>
      </Wrapper>
    </Div100vh>
  );
}
