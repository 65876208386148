import { useEffect, useState, Fragment } from "react";
import Styled from "styled-components";
import { rgba } from "polished";
import queryString from "query-string";
import ReactGA from "react-ga";

import { theme, device } from "../../constants";
import pxToRem from "../../utils/pxToRem";
import urlify from "../../utils/urlify";
import useIsDesktop from "../../utils/useIsDesktop";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

import data from "../../data/ondemand";
import Duration from "../../components/Duration";
import { Video, Pdf, Mp3, Icon365, IconPng } from "./icons";

import Arrow from "./Arrow";
import Modal from "./Modal";

const SLIDE_WIDTH_LG = 236;
const SLIDE_WIDTH_WITH_MARGIN = SLIDE_WIDTH_LG + 10;

const iconsByType = {
  video: Video,
  pdf: Pdf,
  audio: Mp3,
  "365": Icon365,
  png: IconPng
};

const Wrapper = Styled.div`
  background: #071221;
  min-height: 100vh;
  padding: ${pxToRem(87)} 0 ${pxToRem(160)};
  position: relative;
  color: ${theme.white};

  @media ${device.tablet} {
    padding-top: ${pxToRem(180)};
  }

  @media ${device.desktop} {
    padding-top: ${pxToRem(150)};
  }
`;

const Section = Styled.div`
`;

const SectionTitle = Styled.h1`
  font-size: ${pxToRem(32)};
  line-height: ${pxToRem(30)};


  ${theme.font_display};
  text-transform: uppercase;

  @media ${device.tablet} {
    font-size: ${pxToRem(40)};
    line-height: ${pxToRem(40)};
  }

  @media ${device.desktop} {
    font-size: ${pxToRem(38)};
    line-height: ${pxToRem(46)};
  }
`;

const Count = Styled.div`
  font-size: ${pxToRem(32)};
  line-height: ${pxToRem(30)};
  ${theme.font_display};
  text-transform: uppercase;
  color: ${rgba(theme.white, 0.3)};
  flex: 0 0 auto;
  margin-left: 10px;

  @media ${device.desktop} {
    display: none;
  }
`;

const ArrowWrapper = Styled.button`
  width: 34px;
  height: 34px;
  border: 2px solid #fff;
  background: transparent;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 300ms ease-in;

  svg {
    height: 100%;
    width: 100%;
  }

  &:focus {
    outline: none;
  }

  &:first-child {
    transform: rotate(-180deg);
    margin-right: 9px;
  }
`;

const SectionHeader = Styled.div`
  border-bottom: 2px solid ${rgba(theme.white, 0.2)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  margin: 0 10px;

  @media ${device.desktop} {
    margin: 0 ${pxToRem(25)};
  }
`;

const Intro = Styled.div`
  font-size: ${pxToRem(15)};
  line-height: ${pxToRem(19)};
  margin: 10px 10px 0;

  @media ${device.tablet} {
    font-size: ${pxToRem(12)};
    line-height: ${pxToRem(16)};
    max-width: ${pxToRem(347)};
  }

  @media ${device.desktop} {
    font-size: ${pxToRem(14)};
    line-height: ${pxToRem(18)};
    max-width: ${pxToRem(410)};
    margin: 10px ${pxToRem(25)} 0;
  }
`;

const Slider = Styled.div`
  margin: 25px 0 25px 10px;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }

  @media ${device.desktop} {
    margin-left: ${pxToRem(25)};
  }
`;

const Slide = Styled.div`
  width: 240px;
  padding: 4px 4px 7px 4px;
  background: ${theme.white};
  border-radius: 5px;
  flex: 0 0 auto;
  margin-right: 10px;
  color: ${theme.black};
  text-align: left;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-decoration: none;

  @media ${device.tablet} {
    width: 236px;
    padding: 6px 6px 9px 6px;
  }

  @media ${device.desktop} {
    width: ${SLIDE_WIDTH_LG}px;
    padding: 7px 7px 10px 7px;
  }

  &:focus {
    outline: none;
  }
`;

const SlideInner = Styled.div``;

const ImgWrapper = Styled.div`
  position: relative;
  margin-bottom: 6px;
  min-height: 125px;
`;

const Image = Styled.img`
  width: 100%;
  vertical-align: -webkit-baseline-middle;
  border-radius: 5px;
`;

const Title = Styled.p`
  font-size: ${pxToRem(16)};
  line-height: ${pxToRem(22)};
  margin-bottom: ${pxToRem(5)};

  @media ${device.desktop} {
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(20)};
  }
`;

const Description = Styled.p`
  font-size: ${pxToRem(13)};
  line-height: ${pxToRem(17)};
  min-height: ${pxToRem(26)};
  margin-bottom: ${pxToRem(2)};

  @media ${device.desktop} {
    font-size: ${pxToRem(12)};
    line-height: ${pxToRem(16)};
  }
`;

const Time = Styled.p`
  font-size: ${pxToRem(12)};
  line-height: ${pxToRem(6)};
  color: ${rgba("#415A6C", 0.8)};

  @media ${device.desktop} {
      font-size: ${pxToRem(10)};
  }
`;

const Cta = Styled.div`
  background: ${theme.white};
  width: 44px;
  height: 44px;
  position: absolute;
  bottom: -1px;
  left: -1px;
  border-top-right-radius: 5px;
  padding: 5px 5px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 100%;
  }
`;

const Margin = Styled.div`
  padding: 0 4px;
  width: 100%;

  @media ${device.tablet} {
    padding: 0 7px;
  }

  @media ${device.desktop} {
    padding: 0 7px;
  }
`;

const Arrows = Styled.div`
  display: none;
  @media ${device.desktop} {
    display: flex;
  }
`;

const InnerSlider = Styled.div`
  display: flex;
`;

const formatBySections = data => {
  return data.reduce((total, current) => {
    return {
      ...total,
      [current.category]: {
        intro: !!current.catcopy
          ? current.catcopy
          : total[current.category]?.intro,
        items: total[current.category]?.items
          ? [...total[current.category]?.items, current]
          : [current]
      }
    };
    // return {
    //   ...total,
    //   [current.category]: total[current.category]
    //     ? [...total[current.category], current]
    //     : [current]
    // };
  }, {});
};

const formatDuration = data => {
  let durationInMinutes = 1;
  if (data) {
    const duration = data?.split(":");
    if (duration) {
      if (duration.length === 2) {
        const hoursInMinutes = parseInt(duration[0]) * 60;
        durationInMinutes = hoursInMinutes + parseInt(duration[1]);
      } else {
        durationInMinutes = parseInt(duration[0]);
      }
    }
  }

  return durationInMinutes;
};

export default function OnDemand() {
  const [sections, setSections] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedSlide, setSelectedSlide] = useState({});
  const [scrollPerSections, setScrollPerSections] = useState({});
  const [isInitiated, setInitiated] = useState(false);
  const isDesktop = useIsDesktop();

  useEffect(() => {
    const dataBySections = formatBySections(data);

    setScrollPerSections(
      Object.keys(dataBySections).reduce((total, current) => {
        const id = urlify(current);
        return {
          ...total,
          [id]: {
            left: 0,
            isMax: false
          }
        };
      }, {})
    );

    setSections(dataBySections);

    setInitiated(true);
  }, []);

  useEffect(
    () => {
      if (isDesktop && isInitiated) {
        // reset our count
        setScrollPerSections(scrollPerSections =>
          Object.keys(scrollPerSections).reduce((total, current) => {
            const id = urlify(current);
            const slider = document.getElementById(`${id}-slider`);
            slider.scroll({
              left: 0,
              behavior: "smooth"
            });
            return {
              ...total,
              [id]: {
                left: 0,
                isMax: false
              }
            };
          }, {})
        );
      }
    },
    [isDesktop, isInitiated]
  );

  useEffect(
    () => {
      if (!isLoaded) {
        const category = queryString.parse(window.location.search)?.category;

        if (category) {
          // const item = data.find(slide => slide.uid === uid);
          // if (item.type === "video") {
          //   setSelectedSlide(item);
          //   setModalIsOpen(true);
          // }

          const element = document.getElementById(`${category}`);
          if (element) {
            element.scrollIntoView();
          } else {
            // if element hasnt been loaded yet
            // wait 1 sec and try again
            setTimeout(function() {
              const el = document.getElementById(`${category}`);
              if (el) {
                el.scrollIntoView();
              }
            }, 1000);
          }
        }

        setIsLoaded(true);
      }
    },
    [sections, isLoaded]
  );

  const handleScroll = (direction, id) => {
    const slider = document.getElementById(`${id}-slider`);
    const sliderWidth = slider.getBoundingClientRect().width;
    const sliderInner = document
      .getElementById(`${id}-slider-inner`)
      .getBoundingClientRect().width;

    // how many slides can fit in the view
    const slidesPerSlider = sliderWidth / SLIDE_WIDTH_WITH_MARGIN;
    //const fullSlides = Math.floor(slidesPerSlider);

    const percentateOfSlideOutsideView = 1 - (slidesPerSlider % 1);
    const maxScroll = sliderInner - slidesPerSlider * SLIDE_WIDTH_WITH_MARGIN;

    let newScroll;
    let isMax = false;
    const currentScroll = scrollPerSections[id].left;
    if (direction === "next") {
      newScroll = currentScroll + SLIDE_WIDTH_WITH_MARGIN;
      // cant scroll a full slide
      if (newScroll >= maxScroll) {
        // can we scroll the percentage to the outside slide
        newScroll =
          currentScroll +
          SLIDE_WIDTH_WITH_MARGIN * percentateOfSlideOutsideView;
        isMax = true;
        if (newScroll > maxScroll) {
          newScroll = currentScroll;
        }
      }
    } else {
      newScroll =
        currentScroll - SLIDE_WIDTH_WITH_MARGIN < 0
          ? 0
          : currentScroll - SLIDE_WIDTH_WITH_MARGIN;
    }

    slider.scroll({
      left: newScroll,
      behavior: "smooth"
    });
    setScrollPerSections(prevState => ({
      ...prevState,
      [id]: { left: newScroll, isMax }
    }));
  };
  return (
    <Wrapper>
      <Header />
      {Object.keys(sections).map(key => {
        const id = urlify(key);
        const section = sections[key].items;
        return (
          <Section key={key} id={id}>
            <SectionHeader>
              <SectionTitle>{key}</SectionTitle>
              <Count>{section.length} items</Count>
              <Arrows>
                <ArrowWrapper
                  onClick={() => handleScroll("prev", id)}
                  disabled={scrollPerSections[id]?.left === 0}
                >
                  <Arrow />
                </ArrowWrapper>
                <ArrowWrapper
                  onClick={() => handleScroll("next", id)}
                  disabled={scrollPerSections[id]?.isMax}
                >
                  <Arrow />
                </ArrowWrapper>
              </Arrows>
            </SectionHeader>
            <Intro>{sections[key].intro}</Intro>
            <Slider id={`${id}-slider`}>
              <InnerSlider id={`${id}-slider-inner`}>
                {section.map(slide => {
                  const durationInMinutes = formatDuration(slide.duration);
                  const Icon =
                    iconsByType[slide?.type?.toLowerCase()] || Fragment;
                  const isVideo = slide.type === "video";
                  const props = {
                    as: isVideo ? "button" : "a",
                    onClick: isVideo
                      ? () => {
                          setSelectedSlide(slide);
                          setModalIsOpen(true);
                          ReactGA.event({
                            category: "On-demand",
                            action: `${slide.type} clicked`,
                            label: slide.title
                          });
                        }
                      : () => {
                          ReactGA.event({
                            category: "On-demand",
                            action: `${slide.type} clicked`,
                            label: slide.title
                          });
                        },
                    href: isVideo ? null : slide.link,
                    download: isVideo ? null : slide.title,
                    target: "_blank"
                  };
                  return (
                    <Slide key={slide.uid} id={slide.uid} {...props}>
                      <SlideInner>
                        <ImgWrapper>
                          <Image src={`/on-demand/${slide.image}`} alt="" />
                          <Cta>
                            <Icon />
                          </Cta>
                        </ImgWrapper>
                        <Margin>
                          <Title>{slide.title}</Title>
                          <Description>{slide.decription}</Description>
                        </Margin>
                      </SlideInner>

                      <Margin>
                        <Duration>
                          <Time>
                            {durationInMinutes}{" "}
                            {durationInMinutes > 1 ? "minutes" : "minute"}
                          </Time>
                        </Duration>
                      </Margin>
                    </Slide>
                  );
                })}
              </InnerSlider>
            </Slider>
          </Section>
        );
      })}
      <Footer />

      <Modal
        isOpen={modalIsOpen}
        data={selectedSlide}
        onClose={() => {
          setModalIsOpen(false);
          setSelectedSlide({});
        }}
      />
    </Wrapper>
  );
}
