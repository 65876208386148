import Styled from "styled-components";
import PropTypes from "prop-types";
import moment from "moment";
import { rgba } from "polished";
import ReactGA from "react-ga";
import { theme, event_types, device } from "../../../constants";
import pxToRem from "../../../utils/pxToRem";
import {
  TIME_WIDTH,
  TIME_WIDTH_TABLET,
  TIME_HEIGHT,
  TIME_HEIGHT_TABLET
} from "../constants";
import Button from "./Button";
import Clock from "./Clock";
import Info from "./icons/Info";

const colorsPerTypes = {
  [event_types.LIVE]: "#697EA3",
  [event_types.BREAKOUT]: "#B4BED1",
  [event_types.ON_DEMAND]: "#415B6D"
};

const Wrapper = Styled.div`
  margin-left: ${pxToRem(TIME_WIDTH + 7)};
  margin-top: ${pxToRem(7)};
  margin-bottom: ${pxToRem(7)};
  position: absolute;
  width: calc(100% - ${pxToRem(TIME_WIDTH + 7)});
  border-radius: 10px;
  padding: 10px;
  ${theme.font_regular};
  font-size: ${pxToRem(11)};
  line-height: ${pxToRem(14)};
  color: #071221;
  display: flex;
  flex-direction: column;
  justify-content: space-between;


  background: ${props =>
    props.eventType === event_types.BREAK ? "#D0D4DB" : theme.white};
  border-left: 15px solid ${props =>
    colorsPerTypes[props.eventType] || "transparent"};
  height: ${props => pxToRem(props.duration * TIME_HEIGHT - 14)};
  top: ${props => pxToRem(props.$top * (TIME_HEIGHT / 10))};



  @media ${device.tablet} {
    height: ${props => pxToRem(props.duration * TIME_HEIGHT_TABLET - 14)};
    top: ${props => pxToRem(props.$top * (TIME_HEIGHT_TABLET / 10))};
    margin-left: ${pxToRem(TIME_WIDTH_TABLET + 75)};
    width: calc(100% - ${pxToRem(TIME_WIDTH_TABLET + 75 + 16)});
    padding: 5px 10px;
  }
`;

const Content = Styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height ${TIME_HEIGHT - 14 - 10}px;

  @media ${device.tablet} {
    flex-direction: row;
    justify-content: space-between;
    height ${TIME_HEIGHT_TABLET - 14 - 10}px;
    align-items: center;
  }
`;

const Meta = Styled.div`
  font-size: ${pxToRem(15)};
  display: flex;
  align-items: center;

  @media ${device.tablet} {
    font-size: ${pxToRem(12)};
    line-height: ${pxToRem(18)};
  }
`;

const Location = Styled.div`
  padding-left: 10px;
  border-left: 1px solid ${rgba("#575757", 0.1)};
`;

const Title = Styled.p`
  font-size: ${pxToRem(22)};
  line-height: 1em;
  margin-bottom: 5px;

  @media ${device.tablet} {
    font-size: ${pxToRem(14)};
    line-height: ${pxToRem(18)};
  }

  @media ${device.desktop} {
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(24)};
  }
`;

const Inner = Styled.div`
  display: flex;
`;

export default function CalendarItem({
  duration,
  data,
  dayStartTime,
  onClick
}) {
  // offset since day start
  const differenceInMinutes = moment(data.duration.starttime).diff(
    dayStartTime,
    "minutes"
  );

  const durationInMinutes = moment(data.duration.endtime).diff(
    data.duration.starttime,
    "minutes"
  );

  const durationIn10s = durationInMinutes / 10;
  return (
    <Wrapper
      id={data.duration.starttime}
      duration={durationIn10s}
      $top={differenceInMinutes}
      eventType={data.type}
    >
      <Content>
        <Title>{data.title}</Title>
        <Inner>
          {data.type !== event_types.BREAK && (
            <Info onClick={() => onClick(data)} />
          )}
          {data.link && (
            <Button
              href={data.link}
              type={data.type}
              starttime={data.duration.starttime}
              endtime={data.duration.endtime}
              onClick={() => {
                ReactGA.event({
                  category: "Agenda",
                  action: `${data.type} clicked`
                });
              }}
            />
          )}
        </Inner>
      </Content>
      <Meta>
        <Clock>{durationInMinutes} minutes.</Clock>
        <Location>{data.location}</Location>
      </Meta>
    </Wrapper>
  );
}

CalendarItem.propTypes = {
  data: PropTypes.shape({})
};

CalendarItem.defaultProps = {
  data: {}
};
