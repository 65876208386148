import Styled from "styled-components";
import pxToRem from "../../utils/pxToRem";
import { theme, device } from "../../constants";

const Header = Styled.h1`
  font-size: ${pxToRem(45)};
  line-height: ${pxToRem(40)};
  ${theme.font_display};
  text-transform: uppercase;

  @media ${device.tablet} {
    font-size: ${pxToRem(70)};
    line-height: ${pxToRem(60)};
  }

  @media ${device.desktop} {
    font-size: ${pxToRem(90)};
    line-height: ${pxToRem(80)};
    max-width: ${pxToRem(608)};
  }
`;

const Copy = Styled.p`
  font-size: ${pxToRem(28)};
  line-height: ${pxToRem(36)};
  margin-top: ${pxToRem(13)};

  @media ${device.tablet} {
    font-size: ${pxToRem(18)};
    line-height: ${pxToRem(24)};
    margin-top: ${pxToRem(12)};
  }

  @media ${device.desktop} {
    font-size: ${pxToRem(22)};
    line-height: ${pxToRem(28)};
    margin-top: ${pxToRem(33)};
    max-width: ${pxToRem(608)};
  }
`;

const Content = Styled.div`
  color: ${theme.white};
  .full-text { display: none; }

  @media ${device.tablet} {
    max-width: ${pxToRem(466)};
    .full-text { display: inline; }
  }

  @media ${device.desktop} {
    max-width: unset;
    margin-left: 20%;
    width: 100%;
    .full-text { display: inline; }
  }
`;

export default function Intro() {
  return (
    <Content>
      <Header>Senior Leadership Conference</Header>

      <Copy>
        Explore the hubs and hear from some of our most prominent voices who are
        shaping the future of the company.<br />
        <span className="full-text">
          <br />Use the arrow buttons, at the side of the page, to navigate
          between hubs.
        </span>
      </Copy>
    </Content>
  );
}
