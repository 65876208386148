import Styled from "styled-components";

import { theme, device } from "../../constants";
import pxToRem from "../../utils/pxToRem";

const Title = Styled.h1`
  color: ${theme.copy_dark};
  font-size: ${pxToRem(16)};
  line-height: ${pxToRem(20)};
  margin-bottom: ${pxToRem(20)};
  ${theme.font_regular};

  @media ${device.tablet} {
    font-size: ${pxToRem(18)};
    line-height: ${pxToRem(24)};
    margin-bottom: ${pxToRem(40)};
  }
`;

export default function Intro({ user }) {
  return (
    <Title>
      <strong>Hi {user.firstname},</strong>
      <br />here’s your curated agenda.
    </Title>
  );
}
