import PropTypes from "prop-types";
import Styled from "styled-components";

import { theme, device } from "../../constants";
import pxToRem from "../../utils/pxToRem";

const Wrapper = Styled.div`
  position: relative;
`;

const Copy = Styled.span`
  display: inline-block;
  color: ${theme.secondary};
  font-size: ${pxToRem(14)};
  margin-bottom: ${pxToRem(18)};
  ${theme.font_regular};

  @media ${device.tablet} {
    font-size: ${pxToRem(18)};
    line-height: ${pxToRem(24)};
    margin-bottom: ${pxToRem(11)};
  }
`;

export default function Intro({ children }) {
  return (
    <Wrapper>
      <Copy>{children}</Copy>
    </Wrapper>
  );
}

Intro.propTypes = {
  copy: PropTypes.string
};
