import Styled from "styled-components";
import { rgba } from "polished";
import { theme, device } from "../../constants";
import pxToRem from "../../utils/pxToRem";

const Wrapper = Styled.div`
  display: flex;
  margin: 10px 0;

  @media ${device.tablet} {
    width: 45%;
  }
`;

const ImageWrapper = Styled.div`
  width: 46px;
  margin-right: 8px;
  flex-shrink: 0;
`;

const Img = Styled.img`
  max-width: 100%;
  border-radius: 50%;
`;

const Content = Styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${pxToRem(9)};
  line-height: ${pxToRem(12)};
  justify-content: center;
`;

const Name = Styled.p`
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(18)};

  @media ${device.desktop} {
    font-size: ${pxToRem(20)};
    line-height: 1em;
  }
`;

const Title = Styled.p`
  color: ${rgba(theme.white, 0.4)};

  @media ${device.desktop} {
    font-size: ${pxToRem(15)};
    line-height: 1em;
    margin-top: 5px;
  }
`;

export default function Speaker({ name, pic, title }) {
  return (
    <Wrapper>
      <ImageWrapper>
        {pic && <Img src={`speakers/${pic}`} alt={name} />}
      </ImageWrapper>
      <Content>
        <Name>{name}</Name>
        <Title>{title}</Title>
      </Content>
    </Wrapper>
  );
}
