import React from "react";
import { useHistory } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";

const Auth0ProviderWithHistory = ({ children }) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const custom_domain = process.env.REACT_APP_AUTH0_CUSTOM_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

  const history = useHistory();

  const onRedirectCallback = appState => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={custom_domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback} // Auth0 redirects your users from the Auth0 Universal Login page
      audience={`https://${domain}/api/v2/`}
      scope="read:current_user"
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
