import { useEffect } from "react";
import ReactModal from "react-modal";
import Styled from "styled-components";
import { rgba } from "polished";
import { theme, device } from "../../constants";
import pxToRem from "../../utils/pxToRem";
import CloseIcon from "./CloseIcon";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    minWidth: "50%",
    backgroundColor: "transparent",
    border: "none",
    padding: "0"
  },
  overlay: {
    backgroundColor: rgba("#000", 0.8),
    zIndex: 11
  }
};

const Wrapper = Styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${rgba(theme.white, 0.2)};
  height: 100%;
  color: #2C2C2C;
  border-radius: 15px;
  padding: 10px;

  @media ${device.tablet} {
    max-width: ${pxToRem(712)};
    width: 100%;
    margin: auto;
  }

  @media ${device.desktop} {
    max-width: ${pxToRem(912)};
  }
`;

const Inner = Styled.div`
  width: 100%;
  height: 100%;
  background: ${theme.white};
  padding: 20px 20px 10px;
  border-radius: 15px;
`;

const CloseBtn = Styled.button`
  border: none;
  background: transparent;
  padding: 0;
  width: ${pxToRem(22)};
  height: ${pxToRem(22)};

  @media ${device.tablet} {
  }

  @media ${device.desktop} {
    width: ${pxToRem(29)};
    height: ${pxToRem(29)};
  }
`;

const Header = Styled.div`
  display: flex;
  padding-bottom: ${pxToRem(15)};
  justify-content: space-between;
  align-items: center;

  @media ${device.tablet} {
    margin: 0;
    width: 100%;
    padding-bottom: 10px;
  }

  @media ${device.desktop} {
    padding-bottom: 20px;
  }
`;

const HeaderCopy = Styled.div`
  display: flex;
  flex-direction: column;
`;

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
ReactModal.setAppElement("#root");
export default function Modal({ isOpen, onClose, header, children }) {
  // useEffect(
  //   () => {
  //     if (isOpen) {
  //       // need a timeout as the nav on mobile will unset it when closed
  //       setTimeout(() => {
  //         document.body.style.overflow = "hidden";
  //         document.body.style.background = "blue";
  //       }, 1000);
  //     } else {
  //       document.body.style.overflow = "unset";
  //     }
  //   },
  //   [isOpen]
  // );
  //
  // const handleOnClose = () => {
  //   document.body.style.overflow = "unset";
  //   onClose();
  // };

  return (
    <ReactModal isOpen={isOpen} onRequestClose={onClose} style={customStyles}>
      <Wrapper>
        <Inner>
          <Header>
            <HeaderCopy>{header}</HeaderCopy>
            <CloseBtn onClick={onClose} aria-label="close dialog">
              <CloseIcon />
            </CloseBtn>
          </Header>
          {children}
        </Inner>
      </Wrapper>
    </ReactModal>
  );
}
