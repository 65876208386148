export default function Play() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 115 115">
      <g
        id="video_play_button"
        data-name="video play button"
        transform="translate(-17 -360)"
      >
        <path
          id="Polygon_2"
          data-name="Polygon 2"
          d="M16.9,4.537a3,3,0,0,1,5.2,0l14.321,24.97A3,3,0,0,1,33.821,34H5.179a3,3,0,0,1-2.6-4.493Z"
          transform="translate(97 398) rotate(90)"
          fill="#f8389a"
        />
        <g
          id="Ellipse_6"
          data-name="Ellipse 6"
          transform="translate(17 360)"
          fill="none"
          stroke="#f8389a"
          strokeWidth="4"
        >
          <circle cx="57.5" cy="57.5" r="57.5" stroke="none" />
          <circle cx="57.5" cy="57.5" r="55.5" fill="none" />
        </g>
      </g>
    </svg>
  );
}
