import Globe from "../../components/globe";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

export default function Community() {
  return (
    <>
      <Header />
      <Globe />
      <Footer />
    </>
  );
}
