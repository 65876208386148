import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { routes } from "../constants";
import Loading from "../components/loading";

const ProtectedRoute = ({ component: Component, location, ...args }) => {
  const { isAuthenticated, isLoading } = useAuth0();
  return (
    <Route
      {...args}
      render={props => {
        if (isLoading) return <Loading />;

        console.log("ROUTE:isAuthenticated: ", isAuthenticated);
        console.log("ROUTE: isLoading: ", isLoading);
        if (!isAuthenticated) {
          return (
            <Redirect
              to={{ pathname: routes.LOGIN, state: { from: location } }}
            />
          );
        }
        //
        return <Component {...args} {...props} />;
      }}
      {...args}
    />
  );
};

export default ProtectedRoute;

// import React from "react";
// import { Route } from "react-router-dom";
// import { withAuthenticationRequired } from "@auth0/auth0-react";
// import Loading from "../components/loading";
//
// const ProtectedRoute = ({ component, ...args }) => (
//   <Route
//     component={withAuthenticationRequired(component, {
//       onRedirecting: () => <Loading />
//     })}
//     {...args}
//   />
// );
//
// export default ProtectedRoute;
