import Styled from "styled-components";
import { rgba } from "polished";

import { theme, device } from "../../constants";
import pxToRem from "../../utils/pxToRem";
import Play from "./PlayBtn";

const Wrapper = Styled.button`
  background: ${theme.white};
  width: 100%;
  color: ${theme.black};
  border: none;
  border-radius: 5px;
  text-align: left;
  padding: ${pxToRem(5)};
  box-shadow: 0px 0px 30px ${rgba(0, 0, 0, 0.29)};

  @media ${device.tablet} {
    max-width: ${pxToRem(431)};
    border-radius: 16px;
    padding: ${pxToRem(10)};
  }

  &:focus {
    outline: none;
  }
`;

const Image = Styled.img`
  max-width: 100%;
  vertical-align: -webkit-baseline-middle;
  border-radius: 8px;
`;

const Location = Styled.p`
  font-size: ${pxToRem(19)};
  line-height: ${pxToRem(21)};
  margin: 0 ${pxToRem(10)} ${pxToRem(2)} ${pxToRem(10)};

  @media ${device.tablet} {
    font-size: ${pxToRem(28)};
    line-height: ${pxToRem(28)};
    margin: 0 ${pxToRem(10)} ${pxToRem(6)} ${pxToRem(10)};
  }
`;

const Copy = Styled.p`
  margin-bottom: ${pxToRem(10)};
  line-height: ${pxToRem(16)};
  font-size: ${pxToRem(12)};
  color: ${rgba(theme.black, 0.6)};
  margin: 0 ${pxToRem(10)} ${pxToRem(10)} ${pxToRem(10)};

  @media ${device.tablet} {
    line-height: ${pxToRem(18)};
    font-size: ${pxToRem(14)};
    margin: 0 ${pxToRem(10)} ${pxToRem(10)} ${pxToRem(10)};
  }
`;

const ImageWrapper = Styled.div`
  position: relative;
  margin-bottom: ${pxToRem(15)};

  @media ${device.tablet} {
    margin-bottom: ${pxToRem(20)};
  }
`;

const VideoButtonWrapper = Styled.div`
  position: absolute;
  bottom: -1px;
  left: -1px;
  width: ${pxToRem(76)};
  height: ${pxToRem(76)};
  background: ${theme.white};
  border-top-right-radius: 5px;

  @media ${device.tablet} {
    width: ${pxToRem(63)};
    height: ${pxToRem(63)};
  }

  svg {
    margin: ${pxToRem(5)} ${pxToRem(5)} ${pxToRem(0)} ${pxToRem(0)};

    @media ${device.tablet} {
      margin: ${pxToRem(10)} ${pxToRem(10)} ${pxToRem(0)} ${pxToRem(0)};
    }
  }
`;

export default function Overlay({ img, location, copy, onClick }) {
  return (
    <Wrapper onClick={onClick}>
      <ImageWrapper>
        <Image src={img} />
        <VideoButtonWrapper>
          <Play />
        </VideoButtonWrapper>
      </ImageWrapper>
      <Location>{location}</Location>
      <Copy>{copy}</Copy>
    </Wrapper>
  );
}
