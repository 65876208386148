import Styled from "styled-components";
import { device } from "../../constants";
//import logo from "../../assets/logo-blue.png";
import { small } from "../../assets/logos";

const Wrapper = Styled.div`
  position: absolute;
  top: 40px;
  width: 79px;
  height: 72px;

  @media ${device.mobile} and (max-height: 660px) {
    top: 20px;
    width: 60px;
  }

  @media ${device.tablet} {
    display: none;
  }

  @media ${device.desktop} {
    display: block;
    width: 102;
    height: 93;
    top: -87px;
    transform: translate(-31px, -100%);
  }
`;

const Img = Styled.img`
  width: 100%;
`;

export default function Logo() {
  return (
    <Wrapper>
      <Img src={small} />
    </Wrapper>
  );
}
