import React, { useEffect } from "react";
import Modal from "react-modal";

import Content from "./Content";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    minWidth: "50%",
    backgroundColor: "transparent",
    border: "none",
    padding: "0",
    width: "100%"
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.9)",
    zIndex: 11
  }
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

function Popover({ isOpen, onClose, data }) {
  useEffect(
    () => {
      if (isOpen) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "unset";
      }
    },
    [isOpen]
  );
  console.log("data: ", data);
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={customStyles}>
      <Content
        link={data.link}
        title={data.title}
        streamtype={data.streamtype}
        onClose={onClose}
      />
    </Modal>
  );
}

export default Popover;
