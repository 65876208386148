import Styled from "styled-components";

const Wrapper = Styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  border-top: 1px solid #CECECE;
  padding-top: 12px;
  margin-top: 9px;
  width: 100%;

  svg {
    width: 14px;
    height: 14px;
    margin-right 5px;
  }
`;

export default function Duration({ children }) {
  return (
    <Wrapper>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.38 28.38">
        <g transform="translate(1 1)">
          <circle
            strokeMiterlimit="10"
            fill="none"
            stroke="currentColor"
            strokeWidth="2px"
            cx="13.19"
            cy="13.19"
            r="13.19"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="none"
            stroke="currentColor"
            strokeWidth="2px"
            d="M536.671-3214.121v9.673l5.6,3.673"
            transform="translate(-523.481 3217.638)"
          />
        </g>
      </svg>
      {children}
    </Wrapper>
  );
}
