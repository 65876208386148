import Styled from "styled-components";
import PropTypes from "prop-types";
import { rgba } from "polished";

import { theme } from "../constants";

const Line = Styled.div`
  position: relative;

  &:before {
    content: '';
    width: 100%;
    height: 1px;
    background: ${props => rgba(props.$color, props.$opacity)};
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
`;

export default function Divider({ children, $color, $opacity }) {
  return (
    <Line $color={$color} $opacity={$opacity}>
      {children}
    </Line>
  );
}

Line.propTypes = {
  $color: PropTypes.string,
  $opacity: PropTypes.number
};

Line.defaultProps = {
  $color: theme.white,
  $opacity: 1
};
