import { useContext, useState, useEffect } from "react";
import Styled from "styled-components";
import { rgba } from "polished";
import { useLocation } from "react-router-dom";
//import Countdown from "react-countdown";

import { theme, device } from "../../constants";
import pxToRem from "../../utils/pxToRem";
import { durationInMinutes } from "../../utils/duration-in-minutes";
import { formatSpeakers } from "../../utils/format-speakers";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import HiddenCountdown from "../../components/HiddenCountdown";

import { UserMetaContext } from "../../utils/metadata-context";
import Clock from "../agenda/calendar/Clock";

import Teams from "./Teams";
import Speaker from "./Speaker";
import Slido from "./Slido";

const Wrapper = Styled.div`
  background: #071221;
  min-height: 100vh;
  padding: ${pxToRem(87)} 0 ${pxToRem(160)};
  position: relative;
  color: ${theme.white};

  @media ${device.tablet} {
    padding-top: ${pxToRem(180)};
  }

  @media ${device.desktop} {
    padding: ${pxToRem(150)} ${pxToRem(25)};
  }
`;

const Title = Styled.h1`
  ${theme.font_display};
  font-size: ${pxToRem(32)};
  line-height: ${pxToRem(30)};

  @media ${device.desktop} {
    font-size: ${pxToRem(48)};
    line-height: 1em;
  }
`;

const Description = Styled.p`
  font-size: ${pxToRem(12)};
  line-height: ${pxToRem(15)};
  margin-top: ${pxToRem(12)};

  @media ${device.desktop} {
    font-size: ${pxToRem(15)};
    line-height: 1.2em;
  }
`;

const Inner = Styled.div`
  padding: ${pxToRem(10)} ${pxToRem(10)} 0;

  @media ${device.tablet} {
    padding: ${pxToRem(40)} ${pxToRem(40)} 0;
  }

  @media ${device.desktop} {
    padding: ${pxToRem(40)} 0;
  }
`;

const SlidoWrapper = Styled.div`
  padding: 0 ${pxToRem(10)} ${pxToRem(10)};

  @media ${device.tablet} {
    padding: 0 ${pxToRem(40)} ${pxToRem(40)};
  }

  @media ${device.desktop} {
    padding: 0;
  }
`;

const Speakers = Styled.div`
  border-top: 1px solid ${rgba(theme.white, 0.2)};
  border-bottom: 1px solid ${rgba(theme.white, 0.2)};
  margin: ${pxToRem(15)} 0;

  @media ${device.desktop} {
    display: flex;
    flex-wrap: wrap;
  }
`;

const Duration = Styled.div`
  color: ${rgba(theme.white, 0.4)};
`;

const Content = Styled.div`
  @media ${device.tablet} {
    padding: 0 ${pxToRem(40)};
  }

  @media ${device.desktop} {
    display: flex;
    padding: 0;
    justify-content: center;
  }
`;

const Left = Styled.div`
  @media ${device.desktop} {
    margin-right: ${pxToRem(10)};
    flex: 0 1 66%;
  }
`;

const StateTitle = Styled.h1`
  color: ${theme.primary};
  font-size: ${pxToRem(30)};
  line-height: 1em;
  text-transform: uppercase;
  ${theme.font_display};

  @media ${device.tablet} {
    font-size: ${pxToRem(58)};
  }

  @media ${device.desktop} {
    font-size: ${pxToRem(47)};
  }
`;

const StateInfo = Styled.p`
  color: ${theme.white};
  font-size: ${pxToRem(16)};
  line-height: ${pxToRem(18)};
  text-transform: uppercase;
  ${theme.font_bold};
  margin-top: 6px;

  @media ${device.tablet} {
    margin-top: 8px;
    font-size: ${pxToRem(24)};
    line-height: ${pxToRem(32)};
    max-width: ${pxToRem(414)};
  }

  @media ${device.desktop} {
    margin-top: 18px;
    font-size: ${pxToRem(20)};
    line-height: ${pxToRem(24)};
    max-width: ${pxToRem(364)};
  }
`;

const StateWrapper = Styled.div`
  padding: 0 33px;
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: absolute;
  justify-content: center;
  width: 100%;
  align-items: center;
`;

const DividerWrapper = Styled.div`
  margin: 40px auto;
  max-width: 200px;
  width: 100%;

  @media ${device.mobile} {
    display: none;
  }
`;

const Divider = Styled.div`
  position: relative;

  &:before {
    content: '';
    width: 100%;
    height: 1px;
    background: ${rgba(theme.white, 0.5)};
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
`;

const StateSlido = Styled.div`
  display: none;
  color: ${rgba(theme.white, 0.29)};
  text-transform: uppercase;
  background: #415A6C41;
  @media ${device.desktop} {
    display: flex;
    flex: 1 0 33%;
    align-items: center;
    justify-content: center;
  }
`;

const Hidden = Styled.div`
  display: none;
`;

export default function MainStage() {
  const { state } = useLocation();
  const { user, live, hasEventEnded } = useContext(UserMetaContext);
  const [isLive, setIsLive] = useState(false);
  const [useBackupLink, setUseBackupLink] = useState(false);
  useEffect(
    () => {
      if (state) {
        // get hash from url
        // check if video is still live
        if (state.id === live?.title) {
          setUseBackupLink(true);
        } else {
          setUseBackupLink(false);
        }
      } else {
        setUseBackupLink(false);
      }
    },
    [live, state]
  );

  const speakers = formatSpeakers(live);

  const StateView = ({ children, info }) => {
    return (
      <Content>
        <Left>
          <Teams>
            <StateWrapper>
              <DividerWrapper>
                <Divider />
              </DividerWrapper>
              <StateTitle>
                PLEASE CHECK YOUR AGENDA FOR YOUR CURRENT SESSION OR BREAKOUT
                LINK
              </StateTitle>
              <StateInfo>{info}</StateInfo>
              <DividerWrapper>
                <Divider />
              </DividerWrapper>
            </StateWrapper>
          </Teams>
        </Left>
        <StateSlido>Check Back soon</StateSlido>
        {children}
      </Content>
    );
  };

  const startingSoonRenderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      return (
        <Content>
          <Left>
            <Teams link={useBackupLink ? live[state.key] : live.link} />
            <Inner>
              <Title>{live.title}</Title>
              <Description>{live.desc}</Description>
              {speakers.length > 0 && (
                <Speakers>
                  {speakers.map(speaker => (
                    <Speaker key={speaker.name} {...speaker} />
                  ))}
                </Speakers>
              )}
              <Duration>
                <Clock>{durationInMinutes(live)} minutes.</Clock>
              </Duration>
            </Inner>
          </Left>
          <SlidoWrapper>
            {live.slidourl && <Slido link={live.slidourl} user={user} />}
          </SlidoWrapper>
        </Content>
      );
    } else {
      // Render a countdown
      return (
        <StateView info="MAIN STAGE CURRENTLY OFF-AIR">
          <Hidden>
            {hours}:{minutes}:{seconds}
          </Hidden>
        </StateView>
      );
    }
  };

  return (
    <Wrapper>
      <Header />
      {live ? (
        <HiddenCountdown
          date={live.duration.starttime}
          onComplete={() => setIsLive(true)}
          renderer={startingSoonRenderer}
          isHidden={false}
        />
      ) : (
        <StateView
          info={
            hasEventEnded
              ? "The Senior Leadership Conference has now finished, thank you for joining us. "
              : "That’s the end of the main stage sessions for today."
          }
        />
      )}

      <Footer />
    </Wrapper>
  );
}
