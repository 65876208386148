import Styled from "styled-components";
import pxToRem from "../../utils/pxToRem";
import { theme, device } from "../../constants";

const Copy = Styled.h1`
  color: ${theme.primary};
  font-size: ${pxToRem(50)};
  line-height: ${pxToRem(45)};
  ${theme.font_display};
  text-transform: uppercase;

  @media ${device.tablet} {
    font-size: ${pxToRem(70)};
    line-height: ${pxToRem(60)};
  }

  @media ${device.desktop} {
    font-size: ${pxToRem(70)};
    line-height: ${pxToRem(60)};
    margin-bottom: ${pxToRem(11)};
  }
`;

export default function Title({ children }) {
  return <Copy dangerouslySetInnerHTML={{ __html: children }} />;
}
