const theme = {
  main: "#280031",
  primary: "#FF007F",
  secondary: "#415A6C",
  black: "#000000",
  white: "#FFFFFF",
  copy_dark: "#1F3048",
  grey: "#EAEAEA",
  grey_background: "#E5E7EA",
  energy_display: "EnergyDisplay",
  energy_regular: "EnergyRegular",
  energy_bold: "EnergyBold",
  font_display: 'font-family: "EnergyDisplay", sans-serif;',
  font_regular: 'font-family: "EnergyRegular", sans-serif;',
  font_bold: 'font-family: "EnergyBold", sans-serif;'
};

export default theme;
