import Styled from "styled-components";

const IconWrapper = Styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 7px;
    height: 7px;
  }
`;

export default function Live() {
  return (
    <IconWrapper>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 7">
        <circle fill="#fff" cx="3.5" cy="3.5" r="3.5" />
      </svg>
    </IconWrapper>
  );
}
