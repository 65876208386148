const breakpoints = {
  sm: 768, // if more - use desktop layouts but mobile nav
  md: 1024,
  lg: 1920
};

const device = {
  mobile: `(max-width: ${breakpoints.sm - 1}px)`,
  tablet: `(min-width: ${breakpoints.sm}px)`,
  desktop: `(min-width: ${breakpoints.md}px)`,
  huge: `(min-width: ${breakpoints.lg}px)`
};

const deviceMax = {
  tablet: `(max-width: ${breakpoints.md - 1}px)`,
  desktop: `(max-width: ${breakpoints.lg}px)`
};

export { breakpoints, deviceMax };

// Added the breakpoints to the export so that I can use the numbers for JS responsiveness
export default { ...device, ...breakpoints };
