import { useEffect, useState } from "react";
import { device } from "../constants";
//const QUERY = "(prefers-reduced-motion: no-preference)";
const getInitialState = () => window.matchMedia(device.desktop).matches;

function useIsDesktop() {
  const [isDesktop, setIsDesktop] = useState(getInitialState);
  useEffect(() => {
    const mediaQueryList = window.matchMedia(device.desktop);
    const listener = event => {
      setIsDesktop(event.matches);
    };
    mediaQueryList.addListener(listener);
    return () => {
      mediaQueryList.removeListener(listener);
    };
  }, []);
  return isDesktop;
}

export default useIsDesktop;
