export default function Close() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 142 142">
      <g
        id="Burger_Nav"
        data-name="Burger Nav"
        transform="translate(-876 -580)"
      >
        <g
          id="Ellipse_50"
          data-name="Ellipse 50"
          transform="translate(876 580)"
          fill="none"
          stroke="#fff"
          strokeWidth="4"
        >
          <circle cx="71" cy="71" r="71" stroke="none" />
          <circle cx="71" cy="71" r="69" fill="none" />
        </g>
        <g id="Group_84" data-name="Group 84" transform="translate(0.5 -1.5)">
          <line
            id="Line_138"
            data-name="Line 138"
            x2="61"
            transform="translate(925.433 675.067) rotate(-45)"
            fill="none"
            stroke="#fff"
            strokeWidth="4"
          />
          <line
            id="Line_140"
            data-name="Line 140"
            x2="61"
            transform="translate(925.433 631.933) rotate(45)"
            fill="none"
            stroke="#fff"
            strokeWidth="4"
          />
        </g>
      </g>
    </svg>
  );
}
