import Styled from "styled-components";

const Iframe = Styled.iframe`
  max-width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const IframeWrapper = Styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 */
  background: #415A6C;
`;

export default function Teams({ link, children }) {
  return (
    <IframeWrapper>
      {link ? (
        <Iframe
          title="video"
          src={link}
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
        />
      ) : (
        children
      )}
    </IframeWrapper>
  );
}
