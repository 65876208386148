import Styled from "styled-components";
import { useAuth0 } from "@auth0/auth0-react";

import Divider from "../../components/Divider";
import Cta from "../../components/Cta";
import { theme } from "../../constants";
import pxToRem from "../../utils/pxToRem";

const Wrapper = Styled.div`
  width: 100%;
`;

const Date = Styled.p`
  color: ${theme.white};
  font-size: ${pxToRem(18)};
  line-height: ${pxToRem(23)};
  ${theme.font_regular};
`;

const Title = Styled.h1`
  color: ${theme.white};
  font-size: ${pxToRem(50)};
  line-height: ${pxToRem(45)};
  margin-bottom: ${pxToRem(24)};
  text-transform: uppercase;
  ${theme.font_display};
`;

const Inner = Styled.div`
  padding: ${pxToRem(30)} 0;
`;

export default function Content() {
  const res = useAuth0();
  console.log("res: ", res);
  const { loginWithRedirect } = res;

  return (
    <div>
      <Wrapper>
        <Divider />
        <Inner>
          <Date>23rd – 24th March, 2021</Date>
          <Title>
            Senior Leadership<br />Conference
          </Title>
          <Cta copy="Secure login" onClick={() => loginWithRedirect()} />
        </Inner>
        <Divider />
      </Wrapper>
    </div>
  );
}
