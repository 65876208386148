import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { NavLink } from "react-router-dom";
import Styled from "styled-components";
// import Countdown from "react-countdown";
// import moment from "moment";
//import CountUp from "react-countup";

import { theme, device, routes } from "../../constants";
import pxToRem from "../../utils/pxToRem";
//import Live from "../../screens/agenda/calendar/icons/Live";

import { small, medium, large } from "../../assets/logos";

//import { UserMetaContext } from "../../utils/metadata-context";

import Burger from "./Burger";
import Close from "./Close";
//import Modal from "./Modal";

const HEADER_HEIGHT_SM = 70;
const HEADER_HEIGHT_MD = 85;

const NAV_ITEMS = [
  {
    to: routes.HOME,
    label: "Home"
  },
  {
    to: routes.ON_DEMAND,
    label: "On-demand"
  },
  {
    to: routes.COMMUNITY,
    label: "Community"
  }
];

// {
//   to: routes.COMMUNITY,
//   label: "Community"
// }
// const HEADER_HEIGHT_LG = 71;

const StyledHeader = Styled.header`
  position: absolute;
  z-index: 11;
  height: ${HEADER_HEIGHT_SM}px;
  color: ${theme.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
  top: 0;
  left: 0;

  @media ${device.tablet} {
    height: ${HEADER_HEIGHT_MD}px;
  }

  @media ${device.desktop} {
    padding: 22px 25px;
    height: unset;
  }
`;

const Logo = Styled.picture`
  z-index: 1;
  width: 53px;

  @media ${device.tablet} {
    width: 130px;
  }

  @media ${device.desktop} {
    width: 114px;
    height: 58px;
    flex: 0 0 auto;
  }
`;

const LogoImg = Styled.img`
  max-width: 100%;
`;

const Wrapper = Styled.div`
  display: flex;

  ${props =>
    props.isFullWidth &&
    `@media ${device.mobile} {
      justify-content: space-between;
    }`}

  @media ${device.tablet} {
    align-items: center;
  }
`;

// const Next = Styled(Link)`
//   border: none;
//   background: ${theme.primary};
//   color: ${theme.white};
//   z-index: 1;
//   border-radius: 37px;
//   max-width: 126px;
//   padding: 9px 15px;
//   font-size: ${pxToRem(12)};
//   text-decoration: none;
//   display: flex;
//   align-items: center;
//   text-align: center;
//   margin-left: 27px;
//
//   strong {
//     ${theme.font_bold};
//     text-align: center;
//   }
//
//   @media ${device.desktop} {
//     font-size: ${pxToRem(22)};
//     line-height: ${pxToRem(22)};
//   }
//
//   &:focus {
//     outline: none;
//   }
//
//   ${props =>
//     props.$isHidden &&
//     `@media ${device.mobile} {
//       display: none;
//     }`}
//
//   @media ${device.tablet} {
//     max-width: 166px;
//     line-height: ${pxToRem(20)};
//     font-size: ${pxToRem(16)};
//   }
//
//   @media ${device.desktop} {
//     width: 134px;
//     line-height: ${pxToRem(16)};
//     font-size: ${pxToRem(14)};
//   }
// `;

const Trigger = Styled.button`
  border: none;
  background: transparent;
  color: ${props => (props.mode === "dark" ? theme.secondary : theme.white)};
  width: 48px;
  height: 48px;
  z-index: 1;
  padding: 0;
  margin-left: 9px;

  &:focus {
    outline: none;
  }

  @media ${device.tablet} {
    width: 63px;
    height: 63px;
  }

  @media ${device.desktop} {
    display: none;
  }
`;

const Content = Styled.nav`
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 0 15px;
  padding-top: ${HEADER_HEIGHT_SM + 40}px;
  position: absolute;
  top: -100vh;
  left: 0;
  width: 100%;
  background: ${theme.black};
  overflow: hidden;

  ${props =>
    props.$isOpen &&
    `
    top: 0;
  `}

  @media ${device.desktop} {
    position: static;
    flex-direction: row;
    height: 100%;
    background: transparent;
    padding: 0;
    margin-left: 50px;
    align-items: center;
  }
`;

const LinkItem = Styled(NavLink)`
  color: ${props =>
    props.mode === "dark" && !props.$isOpen ? theme.secondary : theme.white};
  text-decoration: none;
  font-size: ${pxToRem(60)};
  line-height: ${pxToRem(70)};
  ${theme.font_display};
  background: transparent;
  border: none;
  padding: 0;
  text-align: left;
  transition: color 200ms ease-in;

  &:focus {
    outline: none;
  }

  &.active,
  &:hover {
    color: ${theme.primary};
  }


  ${props =>
    props.hiddenSM &&
    `
    display: none;

    @media ${device.desktop} {
      display: unset;
    }
  `}

  ${props =>
    props.hiddenLG &&
    `
    @media ${device.desktop} {
      display: none;
    }
  `}

  @media ${device.desktop} {
    font-size: ${pxToRem(14)};
    line-height: ${pxToRem(18)};
    margin: 0 7px;
    padding: 8px 6px;
    ${theme.font_bold};

    &.active {
      border-radius: 5px;
      background: ${theme.white};
    }
  }
`;

// const renderer = ({ hours, minutes, seconds, completed }) => {
//   if (completed) {
//     // Render a completed state
//     return (
//       <>
//         <Live />
//         <strong>SESSION LIVE</strong>
//       </>
//     );
//   } else {
//     // Render a countdown
//     return (
//       <span>
//         Next session starts in{" "}
//         <strong>
//           {hours > 0 ? `${hours}:` : ""}
//           {minutes < 10 ? `0${minutes}` : minutes}:
//           {seconds < 10 ? `0${seconds}` : seconds}
//         </strong>
//       </span>
//     );
//   }
// };

// const ForwardFund = Styled.span`
//   ${theme.font_regular};
//   display: none;
//
//   @media ${device.desktop} {
//     display: inline-block;
//   }
// `;

// const HiddenCountdown = Styled.div`
//   display: none;
// `;

// const InnerLabel = Styled.span`
//   margin-right: 5px;
// `;

export default function Header({ mode }) {
  const { logout } = useAuth0();
  //const { live, onLiveComplete } = useContext(UserMetaContext);
  const [isOpen, setIsOpen] = useState(false);
  //const [modalIsOpen, setModalIsOpen] = useState(false);
  // const [isLive, setIsLive] = useState(
  //   moment().isAfter(live?.duration?.starttime)
  // );

  useEffect(
    () => {
      if (isOpen) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "unset";
      }
    },
    [isOpen]
  );

  // useEffect(
  //   () => {
  //     //setCurrentLive(live);
  //     setIsLive(moment().isAfter(live?.duration?.starttime));
  //   },
  //   [live]
  // );

  return (
    <StyledHeader>
      <Logo>
        <source srcSet={large} media={device.desktop} />
        <source srcSet={medium} media={device.tablet} />
        <LogoImg src={small} alt="" />
      </Logo>
      <Content $isOpen={isOpen}>
        {NAV_ITEMS.map(item => (
          <LinkItem
            exact={true}
            to={item.to}
            mode={mode}
            $isOpen={isOpen}
            key={item.to}
          >
            {item.label}
          </LinkItem>
        ))}

        <LinkItem
          mode={mode}
          $isOpen={isOpen}
          as="button"
          hiddenLG
          onClick={() =>
            logout({
              returnTo: window.location.origin
            })
          }
        >
          Logout
        </LinkItem>
      </Content>

      <Wrapper isFullWidth={!isOpen}>
        <LinkItem
          hiddenSM
          mode={mode}
          $isOpen={isOpen}
          as="button"
          onClick={() =>
            logout({
              returnTo: window.location.origin
            })
          }
        >
          Logout
        </LinkItem>

        <Trigger onClick={() => setIsOpen(!isOpen)} mode={mode}>
          {isOpen ? <Close /> : <Burger />}
        </Trigger>
      </Wrapper>
    </StyledHeader>
  );
}

// <Modal
//   isOpen={modalIsOpen}
//   onClose={() => setModalIsOpen(false)}
//   amount={forwardFund}
// />

// {hasEventStarted && (
//   <LinkItem
//     as="button"
//     mode={mode}
//     onClick={() => {
//       setModalIsOpen(true);
//       setIsOpen(false);
//       getForwardFund();
//     }}
//   >
//     <InnerLabel>Forward Fund</InnerLabel>
//     <ForwardFund>
//       <CountUp
//         start={Math.round(forwardFund)}
//         end={20000}
//         duration={20000 - Math.round(forwardFund)}
//         decimals={0}
//         formattingFn={value => `$${value.toLocaleString()}`}
//         useEasing={false}
//       />
//     </ForwardFund>
//   </LinkItem>
// )}
