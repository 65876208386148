import Styled from "styled-components";
import { rgba } from "polished";

import { theme, device } from "../../../constants";
import pxToRem from "../../../utils/pxToRem";
import CloseIcon from "./CloseIcon";

const Wrapper = Styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${theme.white};
  height: 100%;

  @media ${device.tablet} {
    max-width: ${pxToRem(620)};
    margin: auto;
  }

  @media ${device.desktop} {
    max-width: ${pxToRem(810)};
  }
`;

const Position = Styled.span`
  font-size: ${pxToRem(18)};
  line-height: ${pxToRem(22)};
  color: ${rgba(theme.white, 0.5)};

  @media ${device.tablet} {
    color: inherit;
  }
`;

const Title = Styled.h2`
  font-size: ${pxToRem(18)};
  line-height: ${pxToRem(22)};

  & > ${Position} {
    display: none;
  }

  @media ${device.tablet} {
    font-size: ${pxToRem(19)};
    line-height: ${pxToRem(23)};

    & > ${Position} {
      display: unset;
    }

    & + ${Position} {
      display: none;
    }
  }

  @media ${device.desktop} {
    font-size: ${pxToRem(25)};
    line-height: ${pxToRem(32)};
  }
`;

const Iframe = Styled.iframe`
  max-width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const IframeWrapper = Styled.div`
  position: relative;
  width: 100%;
  margin-top: ${pxToRem(23)};
  padding-bottom: 56.25%; /* 16:9 */

  @media ${device.desktop} {
    margin-top: ${pxToRem(27)};
  }
`;

const CloseBtn = Styled.button`
  border: none;
  background: transparent;
  padding: 0;
  width: ${pxToRem(22)};
  height: ${pxToRem(22)};

  @media ${device.tablet} {
  }

  @media ${device.desktop} {
    width: ${pxToRem(29)};
    height: ${pxToRem(29)};
  }
`;

const Header = Styled.div`
  display: flex;
  border-bottom: 1px solid #CECECE;
  padding-bottom: ${pxToRem(15)};
  margin: 0 ${pxToRem(20)};
  width: calc(100% - ${pxToRem(20)});
  justify-content: space-between;
  align-items: center;

  @media ${device.tablet} {
    margin: 0;
    width: 100%;
    padding-bottom: 10px;
  }

  @media ${device.desktop} {
    padding-bottom: 28px;
  }
`;

const HeaderCopy = Styled.div`
  display: flex;
  flex-direction: column;
`;

// function getVimeoId(url) {
//   console.log("url: ", url);
//   // Look for a string with 'vimeo', then whatever, then a
//   // forward slash and a group of digits.
//   //var match = /vimeo.*\/(\d+)/i.exec(url);
//   var regExp = /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/;
//
//   var match = url.match(regExp);
//
//   // If the match isn't null (i.e. it matched)
//   if (match) {
//     // The grouped/matched digits from the regex
//     return `https://player.vimeo.com/video/${match[5]}`;
//   }
// }

export default function Content({ title, link, onClose, streamtype }) {
  return (
    <Wrapper>
      <Header>
        <HeaderCopy>
          <Title>{title}</Title>
        </HeaderCopy>
        <CloseBtn onClick={onClose} aria-label="close dialog">
          <CloseIcon />
        </CloseBtn>
      </Header>
      <IframeWrapper>
        <Iframe
          title="video"
          src={link}
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
        />
      </IframeWrapper>
    </Wrapper>
  );
}
