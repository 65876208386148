import Styled from "styled-components";
import image from "./teams-icon.png";

const IconWrapper = Styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 3px;
  padding: 2px;
`;

const Img = Styled.img`
  max-width: 100%;
`;

export default function Live() {
  return (
    <IconWrapper>
      <Img src={image} alt="" />
    </IconWrapper>
  );
}
