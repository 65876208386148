import Styled from "styled-components";
import { rgba } from "polished";

import { theme } from "../constants";
import pxToRem from "../utils/pxToRem";

const Button = Styled.button`
  color: ${theme.white};
  background: ${theme.primary};
  width: 100%;
  height: ${pxToRem(50)};
  border: none;
  border-radius: ${pxToRem(5)};
  font-size: ${pxToRem(18)};
  padding: 0;
  ${theme.font_regular};

  &:focus {
    outline: none;
  }

  &[disabled] {
    background: ${rgba(theme.primary, 0.3)}
  }
`;

export default function Cta({ copy, onClick, isDisabled }) {
  return (
    <Button onClick={onClick} disabled={isDisabled}>
      {copy}
    </Button>
  );
}
