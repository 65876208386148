import React from "react";
import styled from "styled-components";

import { device } from "../../constants";
import backgroundLG from "../../screens/login/login_2048.jpg";
import backgroundSM from "../../screens/login/login_767.jpg";
import backgroundMD from "../../screens/login/login_1023.jpg";
//import loadingImg from "./spinner_1.gif";
import loadingImg from "./spinner.svg";

// const loadingImg =
//   "https://cdn.auth0.com/blog/auth0-react-sample/assets/loading.svg";

const Spinner = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: url(${backgroundSM}) no-repeat center center / cover;

  @media ${device.tablet} {
    background: url(${backgroundMD}) no-repeat center center / cover;
  }

  @media ${device.desktop} {
    background: url(${backgroundLG}) no-repeat center center / cover;
  }

  img {
    width: 82px;
    height: 82px;
  }
`;

const Loading = () => (
  <Spinner>
    <img src={loadingImg} alt="Loading..." />
  </Spinner>
);

export default Loading;
