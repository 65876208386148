import React from "react";
import PropTypes from "prop-types";
import Styled from "styled-components";
//import queryString from "query-string";

import pxToRem from "../../utils/pxToRem";
import { device } from "../../constants";

const Iframe = Styled.iframe`
  min-height: 560px;
`;

// margin-top: -56px;
// @media (min-width: 950px) and (max-width: 1023px) {
//   margin-top: 0;
// }

const Wrapper = Styled.div`
  display: flex;
  overflow: hidden;
  margin-top: ${pxToRem(20)};
  padding: ${pxToRem(10)} 0;

  @media ${device.desktop} {
    margin-top: 0;
    padding: 0 0;
  }
`;

//  border-radius: 10px;
export default function Slido({ link }) {
  // const url = queryString.stringifyUrl({
  //   url: link
  // });

  return (
    <Wrapper>
      <Iframe
        title="slido"
        src={link}
        height="100%"
        width="100%"
        frameBorder="0"
        id="slido-iframe"
      />
    </Wrapper>
  );
}

Slido.propTypes = {
  eventId: PropTypes.string.isRequired
};

Slido.defaultProps = {
  eventId: "jllbf2ji"
};
