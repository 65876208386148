import { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Link } from "react-router-dom";
import Styled from "styled-components";
import Countdown from "react-countdown";

import { theme, event_types, routes, device } from "../../../constants";
import pxToRem from "../../../utils/pxToRem";
import urlify from "../../../utils/urlify";

import LiveIcon from "./icons/Live";
import TeamsIcon from "./icons/Teams";

const Button = Styled.a`
  background: ${theme.primary};
  color: ${theme.white};
  font-size: ${pxToRem(12)};
  text-decoration: none;
  padding: 5px 3px;
  border-radius: 5px;
  width: ${pxToRem(118)};
  display: flex;
  justify-content: center;
  ${theme.font_bold};
  align-items: center;
  border: none;

  @media ${device.tablet} {
    margin-left: 9px;
    height: 28px;
  }

  &:focus,
  &:hover,
  &:visited {
    color: ${theme.white};
  }

  ${props =>
    props.$hasIcon &&
    `
    justify-content: flex-start;
  `}
`;

const Copy = Styled.span`
  margin-right: 5px;
  margin-left: 5px;
  flex: 1 0 auto;
  text-align: center;
`;

const HiddenCountdown = Styled.div`
  display: none;
`;

const BackupLinks = Styled.p`
  margin-top: ${pxToRem(10)};
  color: #888C93;
  font-size: ${pxToRem(12)};
  line-height: ${pxToRem(18)};

  a {
    color: #1F3048;
  }
`;

const getProps = (type, href) => {
  switch (type) {
    case event_types.LIVE:
      return {
        copy: "LIVE NOW",
        icon: LiveIcon,
        props: {
          to: routes.MAIN_STAGE,
          as: Link
        }
      };
    case event_types.ON_DEMAND:
      const isDeepLink = href !== "/";
      const url = isDeepLink
        ? `${routes.ON_DEMAND}?category=${urlify(href)}`
        : `${routes.ON_DEMAND}`;
      return { copy: "On-demand", props: { to: url, as: Link } };
    default:
      return {
        copy: "Join Teams",
        icon: TeamsIcon,
        props: { href, as: "a", target: "_blank" }
      };
  }
};

export default function Cta({
  type,
  href,
  starttime,
  endtime,
  backupLinks,
  id,
  onClick,
  onBackuplinkClick
}) {
  const isPast = moment().isAfter(moment(endtime));
  const isInFuture = moment().isBefore(moment(starttime));
  const isLiveNow = moment().isBetween(starttime, endtime);

  // live events are hidden if in the past or in the future (if not live)
  const disabledByDefault = type === event_types.LIVE && (isPast || isInFuture);
  const [isHidden, setIsHidden] = useState(disabledByDefault);

  const [isLive, setIsLive] = useState(isLiveNow);

  const { copy, icon: Icon, props } = getProps(type, href, isHidden);
  //const hasCountdown = type === event_types.LIVE && isInFuture;

  // for future events
  if (isHidden) {
    return (
      <HiddenCountdown>
        <Countdown
          date={starttime}
          onComplete={() => {
            setIsHidden(false);
            setIsLive(true);
          }}
          key={starttime}
        />
      </HiddenCountdown>
    );
  }

  return (
    <>
      <Button {...props} $hasIcon={Icon} onClick={onClick}>
        {Icon && <Icon />}
        <Copy>{copy}</Copy>
        {isLive && (
          <HiddenCountdown>
            <Countdown // to tell when an event is ending
              date={endtime}
              onComplete={() => {
                setIsHidden(true);
                setIsLive(false);
              }}
              key={endtime}
            />
          </HiddenCountdown>
        )}
      </Button>
      {isLive &&
        backupLinks.length > 0 && (
          <BackupLinks>
            In the event the ‘live now’ link is not working, please use:<br />
            <Link
              onClick={onBackuplinkClick}
              to={{
                pathname: `${routes.MAIN_STAGE}`,
                state: { key: "backuplink1", id }
              }}
            >
              backup video 1
            </Link>
            {backupLinks[1] && " or "}
            {backupLinks[1] && (
              <Link
                to={{
                  pathname: `${routes.MAIN_STAGE}`,
                  state: { key: "backuplink2", id }
                }}
              >
                backup video 2
              </Link>
            )}
          </BackupLinks>
        )}
    </>
  );
}

Cta.propTypes = {
  backupLinks: PropTypes.array
};

Cta.defaultProps = {
  backupLinks: []
};
