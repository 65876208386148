import Styled from "styled-components";

import { theme, device } from "../../constants";
import pxToRem from "../../utils/pxToRem";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

import data from "./data";

const Wrapper = Styled.div`
  background: #071221;
  color: ${theme.white};
  min-height: 100vh;
  position: relative;
`;

const InnerWrapper = Styled.div`
  padding: ${pxToRem(121)} ${pxToRem(10)} ${pxToRem(160)};

  @media ${device.tablet} {
    padding: ${pxToRem(170)} ${pxToRem(85)};
  }

  @media ${device.desktop} {
    padding: ${pxToRem(150)} ${pxToRem(25)};
  }
`;

const Title = Styled.h1`
  font-size: ${pxToRem(42)};
  line-height: ${pxToRem(24)};
  margin-bottom: ${pxToRem(21)};
  text-transform: uppercase;
  ${theme.font_display};

  @media ${device.tablet} {
    text-align: center;
    font-size: ${pxToRem(55)};
    line-height: ${pxToRem(49)};
    margin-bottom: ${pxToRem(55)};
  }

  @media ${device.desktop} {
    font-size: ${pxToRem(60)};
    line-height: ${pxToRem(53)};
    margin-bottom: ${pxToRem(49)};
  }
`;

const Intro = Styled.p`
  font-size: ${pxToRem(12)};
  line-height: ${pxToRem(18)};

  @media ${device.tablet} {
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(22)};
  }

  a {
    color: ${theme.white};

    &:link,
    &:visited,
    &:hover,
    &:focus {
      color: ${theme.white};
    }
  }
`;

const Rtc = Styled.div`
  font-size: ${pxToRem(12)};
  line-height: ${pxToRem(18)};

  @media ${device.tablet} {
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(22)};
  }

  h4 {
    text-decoration: underline;
  }
`;

const Question = Styled.h3`
  ${theme.font_bold};
  margin-top: 1em;
`;

const Answer = Styled.div`
  p {
    margin: 1em 0;
  }

  p:first-child {
    margin-top: 0;
  }

  a {
    color: ${theme.white};

    &:link,
    &:visited,
    &:hover,
    &:focus {
      color: ${theme.white};
    }
  }
`;

const Category = Styled.h2`
  font-size: ${pxToRem(26)};
  line-height: ${pxToRem(27)};
  margin-top: ${pxToRem(24)};
  margin-bottom: ${pxToRem(24)};
  text-transform: uppercase;
  ${theme.font_display};

  @media ${device.tablet} {
    font-size: ${pxToRem(27)};
    line-height: ${pxToRem(37)};
    margin-bottom: ${pxToRem(53)};
    margin-top: ${pxToRem(55)};
  }

  @media ${device.desktop} {
    font-size: ${pxToRem(30)};
    line-height: ${pxToRem(41)};
    margin-bottom: ${pxToRem(49)};
    margin-top: ${pxToRem(49)};
  }
`;

const Content = Styled.div`
  max-width: ${pxToRem(645)};
  margin: auto;
`;

export default function Faqs() {
  return (
    <Wrapper>
      <InnerWrapper>
        <Header />
        <Content>
          <Title>Help desk</Title>
          <Intro>
            Take a look at common questions below. If you’re having a technical
            issue and can’t find the answers here, please use our contact email
            address{" "}
            <a href="mailto:support@protecthealnurture.com">
              support@protecthealnurture.com
            </a>.
          </Intro>

          <Rtc>
            {data.map(category => {
              return (
                <>
                  <Category>{category.title}</Category>
                  {category.items.map((item, index) => (
                    <>
                      <Question>{`${index + 1}. ${item.question}`}</Question>
                      <Answer
                        dangerouslySetInnerHTML={{ __html: item.answer }}
                      />
                    </>
                  ))}
                </>
              );
            })}
          </Rtc>
        </Content>
      </InnerWrapper>
      <Footer />
    </Wrapper>
  );
}
