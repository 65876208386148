import Styled from "styled-components";

import { theme, device } from "../../constants";

const SlideWrapper = Styled.div`
  height: 100%;
  &:focus {
    outline: none;
  }
`;

const Slide = Styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${theme.black};
  background: ${theme.white};
  text-align: center;

  @media ${device.desktop} {
    flex-direction: row-reverse;
    text-align: left;
  }
`;

export default function SlideItem({ children }) {
  return (
    <SlideWrapper>
      <Slide>{children}</Slide>
    </SlideWrapper>
  );
}
