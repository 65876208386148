const routes = {
  HOME: "/",
  LOGIN: "/login", // change when we have a flow
  LIST: "/list", // change when we have a flow
  GLOBE: "/globe",
  LOADING: "/loading",
  COUNTDOWN: "/countdown",
  FIREBASE: "/firebase",
  SLIDO: "/slido",
  ONBOARDING: "/onboarding",
  AGENDA: "/agenda",
  MAIN_STAGE: "/main-stage",
  ON_DEMAND: "/on-demand",
  COMMUNITY: "/community",
  FORWARD_FUND: "/forward-fund",
  FAQ: "/faqs"
};

export default routes;
