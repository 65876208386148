import Styled from "styled-components";

import { theme } from "../../../constants";
import { images } from "./images";
const SlideWrapper = Styled.div`
  height: 100%;
  background: url(${props =>
    images.lg[props.index]}) no-repeat center center / cover;
  &:focus {
    outline: none;
  }
`;

const Slide = Styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${theme.white};
`;

export default function SlideItem({ index, children }) {
  return (
    <SlideWrapper index={index}>
      <Slide>{children}</Slide>
    </SlideWrapper>
  );
}
