import Styled from "styled-components";
import { theme, device } from "../../../constants";

const Image = Styled.img`
  width: 100%;
`;

const ImgWrapper = Styled.div`
  width: 41%;
  min-width: 287px;
  margin-right: 12px;

  @media ${device.desktop} {
    width: 41%;
    min-width: 367px;
  }
`;

export { Image, ImgWrapper };
