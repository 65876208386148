const data = [
  {
    id: 0,
    intro: "Welcome to the",
    title: "Senior Leadership Conference",
    copy:
      "We’re grateful to have you with us as we embark on the next exciting chapter of our story."
  },
  {
    id: 1,
    intro: "How to engage",
    title: "2 key places",
    copy:
      "The leadership conference will take place on this website with links out to Microsoft Teams for breakout sessions. \nPlease stay logged-in to your Microsoft 365 account throughout the conference and follow your agenda on this website to understand where you should be, and when. \nFor the best experience, please view on desktop, laptop or tablet."
  },
  {
    id: 2,
    intro: "The stage is set",
    title: "Visit your agenda to see what’s in store"
  },
  {
    id: 3,
    intro: "Explore the hubs",
    title: "Tour the regions to discover our stories"
  },
  {
    id: 4,
    intro: "Enhance your wellness",
    title: "Strike the balance with our Wellness Boosters"
  },
  {
    id: 5,
    intro: "Our community",
    title: "Explore the community to hear from our people"
  }
];

const safari = {
  id: 6,
  intro: "Watching content",
  title: "As you're using safari",
  isSafari: true
};

export { safari };
export default data;
