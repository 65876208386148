import Styled from "styled-components";
import { device } from "../../../../constants";

const Wrapper = Styled.button`
  background: transparent linear-gradient(0deg, #7C8DAC 0%, #94A2BA 100%) 0% 0% no-repeat padding-box;
  border-radius: 5px;
  width: 28px;
  height: 28px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;

  &:focus {
    outline: none;
  }

  svg {
    width: 100%;
    height: 100%;
  }

  @media ${device.mobile} {
    display: none;
  }
`;

export default function Info({ onClick }) {
  return (
    <Wrapper onClick={onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <g transform="translate(-0.064 -0.287)">
          <g fill="none" stroke="#fff" ransform="translate(0.064 0.287)">
            <circle stroke="none" cx="12" cy="12" r="12" />
            <circle fill="none" cx="12" cy="12" r="11.5" />
          </g>
          <path
            fill="#fff"
            d="M294.417-4022.5a1.315,1.315,0,0,0,1.315-1.315,1.314,1.314,0,0,0-1.315-1.314,1.315,1.315,0,0,0-1.316,1.314A1.316,1.316,0,0,0,294.417-4022.5Z"
            transform="translate(-282.173 4030.177)"
          />
          <path
            fill="#fff"
            d="M295.647-4012.789v-7.8a.625.625,0,0,0-.625-.625h-3.133v1.25H294.4v7.176h-2.508v1.251h6.106v-1.251Z"
            transform="translate(-282.647 4030.929)"
          />
        </g>
      </svg>
    </Wrapper>
  );
}
