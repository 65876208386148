// sm
import sm1 from "./sm/OB1.jpg";
import sm2 from "./sm/OB2.jpg";
import sm3 from "./sm/OB3.jpg";
import sm4 from "./sm/OB4.jpg";
import sm5 from "./sm/OB5.jpg";
import sm6 from "./sm/OB6.jpg";
import sm8 from "./sm/OB8.jpg";

import md1 from "./md/OB1_1023.jpg";
import md2 from "./md/OB2_1023.jpg";
import md3 from "./md/OB3_1023.jpg";
import md4 from "./md/OB4_1023.jpg";
import md5 from "./md/OB5_1023.jpg";
import md6 from "./md/OB6_1023.jpg";
import md8 from "./md/OB8_1023.jpg";

import lg1 from "./lg/OB1_2048.jpg";
import lg2 from "./lg/OB2_2048.jpg";
import lg3 from "./lg/OB3_2048.jpg";
import lg4 from "./lg/OB4_2048.jpg";
import lg5 from "./lg/OB5_2048.jpg";
import lg6 from "./lg/OB6_2048.jpg";
import lg8 from "./lg/OB8_2048.jpg";

const images = [
  {
    sm: sm1,
    md: md1,
    lg: lg1
  },
  {
    sm: sm2,
    md: md2,
    lg: lg2
  },
  {
    sm: sm3,
    md: md3,
    lg: lg3
  },
  {
    sm: sm4,
    md: md4,
    lg: lg4
  },
  {
    sm: sm5,
    md: md5,
    lg: lg5
  },
  {
    sm: sm6,
    md: md6,
    lg: lg6
  },
  {
    sm: sm8,
    md: md8,
    lg: lg8
  }
];

export default images;
