export default [
  {
    id: "1",
    name: "Jyoti Chopra",
    city: "Amsterdam, Netherlands",
    content: "",
    video: "https://web.microsoftstream.com/embed/video/8992f247-4a43-4cae-a753-f575d4551d0f",
    lat: "52.370216",
    long: "4.895168",
    coordinates: [50.370216, 4.895168],
  },
  {
    id: "2",
    name: "Mike Wilson",
    city: "Amsterdam, Netherlands",
    content: "",
    video: "https://web.microsoftstream.com/embed/video/bc402a1d-3608-4c40-8657-13768abcdef0",
    lat: "51.510536",
    long: "-0.595041",
    coordinates: [53.510536, 4.095041],
  },
  {
    id: "3",
    name: "Alice Moore",
    city: "Amsterdam, Netherlands",
    content: "this ones a maybe - if we have too many - feel free to delete..",
    video: "https://web.microsoftstream.com/embed/video/01b7195a-0c54-455e-90a2-c10f4ba5543a",
    lat: "51.510536",
    long: "-0.595041",
    coordinates: [55.510536, 6.595041],
  },
  {
    id: "4",
    name: "Matt Shaw",
    city: "Amsterdam, Netherlands",
    content: "",
    video: "https://web.microsoftstream.com/embed/video/b91a12de-9816-4838-a3d6-9f15d91f5499",
    lat: "51.510536",
    long: "-0.595041",
    coordinates: [51.510536, -0.595041],
  },
  {
    id: "5",
    name: "Nitin Shetty",
    city: "Bang Phli, Thailand",
    content: "",
    video: "https://web.microsoftstream.com/embed/video/8cd09fad-8d60-405b-8735-59d3eadf2d8a",
    lat: "11.60595226",
    long: "99.7061691",
    coordinates: [15.60595226, 97.7061691],
  },
  {
    id: "6",
    name: "Noppadon Adjimatera",
    city: "Bangkok, Thailand",
    content: "",
    video: "https://web.microsoftstream.com/embed/video/dcc4e3d5-6593-4849-b53e-04d65bc9722c",
    lat: "13.7544238",
    long: "100.4930399",
    coordinates: [13.7544238, 100.4930399],
  },
  {
    id: "7",
    name: "Florence Paoli",
    city: "Dubai, UAE",
    content: "",
    video: "https://web.microsoftstream.com/embed/video/ae3a4abd-0176-4224-baee-45eecce98779",
    lat: "25.0750095",
    long: "55.1887609",
    coordinates: [25.0750095, 55.1887609],
  },
  {
    id: "8",
    name: "John Alvey",
    city: "Evansville, USA",
    content: "",
    video: "https://web.microsoftstream.com/embed/video/79f0f8bd-ff17-43f3-8eac-0fd27313a183",
    lat: "37.971558",
    long: "-87.571091",
    coordinates: [37.971558, -87.571091],
  },
  {
    id: "9",
    name: "Avijit Das",
    city: "Gurgaon, India",
    content: "",
    video: "https://web.microsoftstream.com/embed/video/82f1b517-b4a8-4eb2-bb23-8691b2590543",
    lat: "28.4282624",
    long: "77.0027001",
    coordinates: [28.4282624, 77.0027001],
  },
  {
    id: "10",
    name: "Xenia Barth",
    city: "Heidelberg, Germany",
    content: "",
    video: "https://web.microsoftstream.com/embed/video/97c6169a-af26-4a23-97eb-e2a8ffa675e3",
    lat: "49.4093582",
    long: "8.694724",
    coordinates: [50.4093582, 9.694724],
  },
  {
    id: "11",
    name: "Tolga Balta",
    city: "Istanbul, Turkey",
    content: "",
    video: "https://web.microsoftstream.com/embed/video/b159f198-ff70-4b0c-b874-4530bbdb8454",
    lat: "41.00824",
    long: "28.978359",
    coordinates: [41.00824, 28.978359],
  },
  {
    id: "12",
    name: "Tonguc Civelek",
    city: "Kiev, Ukraine",
    content: "",
    video: "https://web.microsoftstream.com/embed/video/bb6317aa-01ef-45a7-aac1-ace60832498d",
    lat: "50.4501",
    long: "30.523399",
    coordinates: [50.4501, 30.523399],
  },
  {
    id: "13",
    name: "Valérie Sieurin",
    city: "London, UK",
    content: "",
    video: "https://web.microsoftstream.com/embed/video/423866d3-8db3-4e47-9cb1-1f8ac56eb4f2",
    lat: "14.599512",
    long: "120.984222",
    coordinates: [14.599512, 120.984222],
  },
  {
    id: "14",
    name: "Aleli Arcilla",
    city: "Manila, Philippines",
    content: "",
    video: "https://web.microsoftstream.com/embed/video/d4dd237f-8466-4603-9fb5-974b7997b2a0",
    lat: "14.599512",
    long: "120.984222",
    coordinates: [11.599512, 120.984222],
  },
  {
    id: "15",
    name: "Justina Eidukaite",
    city: "Milan, Italy",
    content: "",
    video: "https://web.microsoftstream.com/embed/video/8fd31c44-6846-48ef-b1a5-21fdf9955398",
    lat: "45.464203",
    long: "9.189982",
    coordinates: [44.464203, 11.189982],
  },
  {
    id: "16",
    name: "Frederic Morlie",
    city: "Paris, France",
    content: "",
    video: "https://web.microsoftstream.com/embed/video/9db1263d-3ecd-461a-8642-a34b76258980",
    lat: "48.856613",
    long: "2.352222",
    coordinates: [47.856613, 2.352222],
  },
  {
    id: "17",
    name: "Marco Monti",
    city: "Amsterdam, Netherlands",
    content: "",
    video: "https://web.microsoftstream.com/embed/video/1ba0cdbd-387a-49e2-861a-b611fb9113d1",
    lat: "52.370216",
    long: "4.895168",
    coordinates: [53.370216, 8.895168],
  },
  {
    id: "18",
    name: "Christine Geissler",
    city: "Parsipanny, US",
    content: "",
    video: "https://web.microsoftstream.com/embed/video/57bebdcb-b014-49eb-bd51-676fbf9c4f9e",
    lat: "40.864947",
    long: "-74.4238",
    coordinates: [40.864947, -74.4238],
  },
  {
    id: "19",
    name: "Barney Collins",
    city: "Parsipanny, US",
    content: "",
    video: "https://web.microsoftstream.com/embed/video/b5e93245-40c9-49c2-8d7b-2aee3023d78b",
    lat: "40.864947",
    long: "-74.4238",
    coordinates: [43.864947, -74.4238],
  },
  {
    id: "20",
    name: "Lynn Kenney",
    city: "Parsippany, USA",
    content: "",
    video: "https://web.microsoftstream.com/embed/video/ec978d43-2463-4598-a21e-0b1abd570d7e",
    lat: "40.864947",
    long: "-74.4238",
    coordinates: [41.864947, -77.4238],
  },
  {
    id: "21",
    name: "Renata Moraes",
    city: "São Paulo Brazil",
    content: "",
    video: "https://web.microsoftstream.com/embed/video/0a43cac8-27b9-4f2b-8a4e-2f24e39ff9a3",
    lat: "-23.55052",
    long: "-46.633308",
    coordinates: [-23.55052, -46.633308],
  },
  {
    id: "22",
    name: "Gonzalo Balcázar",
    city: "Selangor, Malaysia",
    content: "",
    video: "https://web.microsoftstream.com/embed/video/f3ea0b62-c606-4234-801c-8d65e13bc9ec",
    lat: "3.2083304",
    long: "101.304146",
    coordinates: [3.2083304, 101.304146],
  },
  {
    id: "23",
    name: "Benny Zhu",
    city: "Singapore, Singapore",
    content: "",
    video: "https://web.microsoftstream.com/embed/video/20445dc2-b009-4c49-aa8b-7d97d9f13bdd",
    lat: "1.357107",
    long: "103.8194992",
    coordinates: [1.357107, 103.8194992],
  },
  {
    id: "24",
    name: "Peggy Cao",
    city: "Slough, England",
    content: "",
    video: "https://web.microsoftstream.com/embed/video/bca7c9fc-47e2-4f3d-a6ab-ea5e8ef8d203",
    lat: "51.510536",
    long: "-0.595041",
    coordinates: [48.510536, -1.595041],
  },
  {
    id: "25",
    name: "Andrea Zaragoza",
    city: "Slough, England",
    content: "",
    video: "https://web.microsoftstream.com/embed/video/cb27b851-22ad-402d-9823-bf4bca47be33",
    lat: "51.510536",
    long: "-0.595041",
    coordinates: [51.510536, -4.595041],
  },
  {
    id: "26",
    name: "Matias Salinas",
    city: "Slough, England",
    content: "",
    video: "https://web.microsoftstream.com/embed/video/3256291f-4bc7-4b93-97a5-88fc046b8db9",
    lat: "51.510536",
    long: "-0.595041",
    coordinates: [54.510536, -0.595041],
  },
  {
    id: "27",
    name: "Natalie Delgado",
    city: "Slough, UK",
    content: "",
    video: "https://web.microsoftstream.com/embed/video/d54c1e6e-500a-47c7-b233-22576f740234",
    lat: "51.510536",
    long: "-0.595041",
    coordinates: [57.510536, -2.395041],
  },
  {
    id: "28",
    name: "Robert Cleveland",
    city: "Slough, UK",
    content: "",
    video: "https://web.microsoftstream.com/embed/video/1ad70a4a-b8ce-4944-96de-eb9f3b68929e",
    lat: "51.510536",
    long: "-0.595041",
    coordinates: [48.510536, -5.595041],
  },
  {
    id: "29",
    name: "Fabrizia Rodriguez Marranzini",
    city: "Slough, UK",
    content: "",
    video: "https://web.microsoftstream.com/embed/video/3d0defe9-91fc-43ae-bcd6-c266cdc1020b",
    lat: "51.510536",
    long: "-0.595041",
    coordinates: [54.510536, -5.095041],
  },
  {
    id: "30",
    name: "Sami Naffakh",
    city: "Slough, UK",
    content: "",
    video: "https://web.microsoftstream.com/embed/video/6d3333e2-e00e-4adf-9c8a-4385eebacbba",
    lat: "51.510536",
    long: "-0.595041",
    coordinates: [51.510536, -8.595041],
  },
  {
    id: "31",
    name: "Saurabh Jain",
    city: "Sydney, Australia",
    content: "",
    video: "https://web.microsoftstream.com/embed/video/63b2858d-ca89-4673-90c5-6f38f96dc047",
    lat: "-33.8641922",
    long: "151.0979607",
    coordinates: [-33.8641922, 151.0979607],
  },
  {
    id: "32",
    name: "Marcin Kaluzny",
    city: "Warsaw Poland",
    content: "",
    video: "https://web.microsoftstream.com/embed/video/7ec2dc5d-790b-4311-b883-1f44b7a30cdc",
    lat: "52.229675",
    long: "21.01223",
    coordinates: [52.229675, 21.01223],
  },
  {
    id: "33",
    name: "Audrae Erickson",
    city: "Washington DC, USA",
    content: "",
    video: "https://web.microsoftstream.com/embed/video/76474650-9f1c-498a-98f5-db12238d5fa3",
    lat: "38.8950368",
    long: "-77.0365427",
    coordinates: [38.8950368, -77.0365427],
  },
  {
    id: "34",
    name: "Carmelo Siragusano",
    city: "Zurich, Switzerland",
    content: "",
    video: "https://web.microsoftstream.com/embed/video/259223c3-f8ad-4652-b437-6eea5fd6e244",
    lat: "47.3744489",
    long: "8.5410422",
    coordinates: [47.3744489, 8.5410422],
  }
];
