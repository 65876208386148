import { useState, useEffect } from "react";
import Styled from "styled-components";
//import "firebase/database";
import ReactGA from "react-ga";

import useLocalStorage from "../../utils/use-local-storage";
//import { FirebaseContext } from "../../utils/firebase";
import { device } from "../../constants";
//import { keys } from "../../constants/firebase";

import Onboarding from "../onboarding";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import HomeCarousel from "./carousel";
import List from "./list";
import Modal from "./Modal";
import ModalContent from "./ModalContent";
import BackgroundSm from "./images/background-sm.png";
import data from "./data";

const CarouselWrapper = Styled.div`
  flex: 1 0 auto;
  @media ${device.mobile} {
    display: none;
  }
`;

const ListWrapper = Styled.div`
  flex: 1 0 auto;
  @media ${device.tablet} {
    display: none;
  }
`;

const Wrapper = Styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  min-height: 100vh;
  position: relative;

  @media ${device.mobile} {
    background: url(${BackgroundSm}) #000 no-repeat;
    background-size: contain;
    background-position: top center;
  }

  .slick-slider {
    flex: 1 0 auto;
  }
`;

export default function Poc() {
  // const firebase = useContext(FirebaseContext);
  // const [charities, setCharities] = useState({});
  const [hasCompletedOnboarding, setHasCompletedOnboarding] = useState(true);
  const [selected, setSelected] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const onModalClose = () => {
    setSelected(null);
    setIsOpen(false);
  };
  const onClick = key => {
    setSelected(key);
    setIsOpen(true);

    ReactGA.event({
      category: "Homepage",
      action: "Video play",
      label: data[key]?.name
    });
  };

  // useEffect(
  //   () => {
  //     const charitiesRef = firebase.database().ref(keys.CHARITIES);
  //
  //     charitiesRef.on("value", snapshot => {
  //       const data = snapshot.val();
  //       // console.log("app update: ", data);
  //       setCharities(data ? data : {});
  //     });
  //   },
  //   [firebase]
  // );

  const handleOnboardingComplete = charity => {
    // store onboarding done in local storage & close onboarding
    setHasCompletedOnboarding(true);

    // store selected charity in firebase
    // firebase
    //   .database()
    //   .ref(keys.CHARITIES)
    //   .set({
    //     ...charities,
    //     [charity]: charities[charity] ? charities[charity] + 1 : 1
    //   });
  };

  useEffect(
    () => {
      if (isOpen) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "unset";
      }
    },
    [isOpen]
  );

  return (
    <>
      {hasCompletedOnboarding ? (
        <Wrapper>
          <Header />
          <CarouselWrapper>
            <HomeCarousel onClick={onClick} />
          </CarouselWrapper>
          <ListWrapper>
            <List onClick={onClick} />
          </ListWrapper>
          <Footer />
        </Wrapper>
      ) : (
        <Onboarding onComplete={handleOnboardingComplete} />
      )}

      <Modal isOpen={isOpen} onClose={onModalClose}>
        <ModalContent {...data[selected]} onClose={onModalClose} />
      </Modal>
    </>
  );
}
